@mixin btn-states($color, $bg-color, $bg-color-hover, $color-hover: '', $shadow-color: '') {
  color: $color;
  border-color: $bg-color;
  background-color: $bg-color;

  @if $color-hover == '' {
    $color-hover: $color;
  }

  &[disabled]:hover,
  &-disabled:hover,
  &[disabled]:active,
  &-disabled:active {
    color: $color;
    border-color: $bg-color;
    background-color: $bg-color;
  }

  &:hover {
    color: $color-hover;
    border-color: darken($bg-color, 4%);
    background-color: $bg-color-hover;
  }

  &:active {
    color: $color-hover;
    background-color: darken($bg-color-hover, 6%);
  }
}

@mixin add-btn-colors($map: $theme-colors-set) {
  @each $val in $map {
    $text-color: theme-color($val, 'text');

    @if not $text-color {
      $text-color: color('white');
    }

    &-#{$val} {
      @include btn-states(
        $color: $text-color,
        $bg-color: theme-color($val),
        $bg-color-hover: theme-color($val, 'light')
      );
    }
  }
}

.hh-btn {
  display: inline-block;
  position: relative;
  padding: 0.5rem 1.4rem 0.46rem;
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: 600;
  font-family: $font-family-montserrat;
  text-align: center;
  word-break: break-word;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: $border-radius;
  transition: 0.06s;

  @include add-btn-colors;

  &:not(:focus-visible) {
    outline: none;
  }

  &-sm {
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    line-height: 1.3;
  }

  &-md {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }

  &-lg {
    padding: 0.6rem 1.8rem;
    font-size: 1.2rem;
    line-height: $font-line-height;
  }

  &-icon {
    > span {
      padding-right: 0.5rem;
    }

    &-begin {
      > span {
        padding-left: 0.5rem;
        padding-right: 0;
      }
    }

    &-animated {
      > span {
        position: relative;
        left: 0;
        transition: 0.2s;
      }

      .icon {
        @include invisible;
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: 0.3s;
        transform: translateY(-50%);
      }

      &:hover {
        > span {
          left: -0.5rem;
        }

        .icon {
          @include visible;
          right: 0.9rem;
        }
      }
    }
  }

  &-rounded {
    border-radius: 30px;
  }

  &-disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}
