html {
  font-size: $font-size-root;
  min-width: 300px;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.sidebar-open {
    overflow: hidden;
  }
}

body {
  font-family: $font-family-open-sans;
  font-size: $font-size;
  color: color('default');
  background-color: color('white');
  line-height: $font-line-height;
  font-weight: $font-weight;
}

body,
html {
  overflow-x: hidden;
}
