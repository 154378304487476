#appointment-access-form {
  margin-bottom: 0;
}

.register-clinic {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .btn-get-started {
    background-color: #f5b400 !important;
    border-radius: 25px;
    width: 50%;
    color: #1a446c !important;
    font-weight: 600;
  }
}
