// Form Field
.hh-form-field {
  margin-bottom: 1rem;
}

// Form Controls
.hh-form-control {
  padding: 16px;
  width: 100%;
  color: color('default');
  font-size: $font-size;
  line-height: 1.3;
  border: 1px solid color-gray('500');
  background-color: color('white');
  border-radius: $form-control-border-radius;
  transition: $transition;

  &:focus,
  &-focus {
    border-color: theme-color('primary');
    outline: none;
  }

  &[readonly],
  &:disabled {
    background-color: color-gray('200');

    &:focus {
      border-color: color-gray();
    }
  }

  &-invalid {
    border-color: theme-color('danger');

    &:focus {
      border-color: theme-color('danger');
    }
  }

  &-rounded {
    border-radius: 30px;
  }

  &::placeholder {
    font-weight: 400;
    color: color-gray('gray');
  }
}

.hh-form-label {
  font-size: 0.9rem;

  &-required {
    &::after {
      content: ' *';
    }
  }

  &-lg {
    font-size: 1.1rem;
  }
}

.hh-form-checkbox {
  cursor: pointer;
  font-weight: 400;

  a {
    @extend .hh-link !optional;
    text-decoration: underline !important;
    border-bottom: 0 !important;
  }
}

#captcha-widget {
  position: relative;
  padding: 0 0 15px 0;
}

// Validation
.hh-form-error {
  padding: 0.3rem 0;
  font-size: 0.9rem;
  color: theme-color('danger');
}

.hh-form-textarea {
  min-height: 150px;
}

.hh-custom-file {
  position: relative;
  cursor: pointer;
  margin: 0;
}
