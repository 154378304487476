// Survey JS - Questionnaire

%question-as-buttons {
  display: grid;
  width: auto;

  label {
    width: 100%;
    text-align: center;
    @extend .btn;
    @extend .btn-default;

    input {
      position: absolute;
      width: 0;
      opacity: 0;
      height: 0;
      visibility: hidden;
    }
  }

  .checked {
    label {
      @extend .btn-info;
    }
  }

  legend {
    display: none;
  }
}

.questionnaire-page {
  .panel-body {
    padding: 0;
  }
}

#questionnaires-sidebar {
  .sidebar {
    width: 450px;
  }

  .sv_main {
    padding-top: 0 !important;
  }

  .hh-btn {
    padding: 0.438rem 1.125rem;
  }

  .hh-btn:hover {
    cursor: pointer;
  }

  .navigation {
    margin-top: 10px;
    text-align: right;
  }

  .question {
    margin: 10px 0;
  }

  .alert-danger {
    padding: 15px 10px 0;
    margin-top: 5px;
  }
}

#questionnaire {
  p, ul {
    margin: 0 0 10px;
  }

  ul {
    padding: 0;
    padding-inline-start: 40px;
  }

  li {
    list-style-type: disc;
  }

  .sv_container {
    h4[data-bind*='cssTitle'] {
      margin: 20px 0 30px;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      line-height: 26px;
      border-bottom: 1px solid #5bc0de;

      .booking-widget & {
        margin-top: 0;
      }

      + .small {
        margin-bottom: 30px;
        font-size: 17px;
        text-align: left;
      }
    }

    .sv_header__text {
      h3 {
        @extend .hh-h4 !optional;
      }
    }
  }

  .sv_main {
    padding-top: 20px;

    .booking-widget & {
      padding-top: 0;
    }

    // Question
    .sv_qstn {
      padding: 10px 0 20px;
      text-align: left;

      a {
        color: $c-primary;
      }

      p {
        line-height: $font-line-height;
      }

      h4 {
        line-height: $font-line-height;
        font-size: 1rem;
      }

      h5 {
        font-size: 1rem;
        margin: 0 0 20px;
      }

      .form-control {
        &:focus {
          box-shadow: none;
        }
      }
    }

    // Question number
    .sv_q_num {
      display: none;
    }

    // Checkbox and Radio
    .sv_qcbc {
      padding: 0;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 18px;

      @include breakpoint(min-width, $screen-xs-max) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include breakpoint(min-width, $screen-sm-min) {
        grid-template-columns: repeat(5, 1fr);
      }

      label {
        display: flex;
        align-items: center;
        line-height: 22px;
      }

      legend {
        display: none;
      }
    }

    // Checkbox
    .sv_qcbx {
      .checkbox-material {
        margin: 0;
      }
    }

    .radio,
    .checkbox {
      margin: 0 0 7px;
      padding: 0;
    }

    .checkbox label,
    .radio label {
      padding-left: 0;
    }

    // Radio
    .radio input[type='radio'],
    .radio-inline input[type='radio'],
    .checkbox input[type='checkbox'],
    .checkbox-inline input[type='checkbox'] {
      position: relative;
      margin-left: 0;
      margin-right: 10px;
    }

    // Boolean / Switch
    .sv-boolean__label--disabled {
      opacity: 0.5;
    }

    .sv-boolean__switch {
      background-color: $c-light-gray;
    }

    .sv-boolean--checked .sv-boolean__switch {
      background-color: $c-light-blue;
    }

    // Lists
    ul {
      list-style: initial;
      margin: initial;
      padding: 0 0 0 40px;
    }

    li {
      list-style: initial;
    }

    // Validation
    .sv_qstn_error_top {
      .glyphicon {
        display: none;
      }
    }

    // Footer
    .panel-footer {
      @extend .clearfix;
      margin-bottom: 20px;
      padding-right: 0;
      padding-left: 0;
      background: none;
      border: none;

      .btn {
        float: left;
        font-size: 16px;
      }

      .btn:not(.sv_prev_btn) {
        @extend .btn-info;
      }

      .sv_next_btn,
      .sv_complete_btn {
        float: right;
      }

      .sv_prev_btn {
        @extend .btn-default;
      }
    }
  }

  .sv-visuallyhidden {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
  }
}

input[type='radio']:checked + label.dynamic-label {
  background-color: $c-green;
}

.sv-popup {
  z-index: 2001 !important;
}

.sd-root-modern {
  background-color: #fff !important;
}

.options {
  .preview-radio, .answer-radio {
    opacity: 0;
    position: fixed;
  }

  .preview-label, .answer-label {
    width: 160px;
  }

  .preview-text, .answer-text {
    width: 200px;
  }
}
