.va-form {
  .form-group.form-inline .field_with_errors {
    display: inline-block;
  }
}

#patient-video-appointment {
  @extend #patient-video-app;
  padding: 0px;
}

#publisher-container {
  min-height: 120px;

  * {
    border-radius: 5px;
  }
}

#vc-session-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
}

#vc-hang-up {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;

  @media (max-width: 767px) {
    img {
      width: 50px;
      height: 50px;
    }
  }

  @media (min-width: 768px) {
    img {
      width: 75px;
      height: 75px;
    }
  }

  img:hover {
    opacity: 0.8;
  }
}

#vc-toggle-camera {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  border: none;
  background: none;
  outline: none;

  @media (max-width: 767px) {
    img {
      width: 50px;
      height: 50px;
    }
  }

  @media (min-width: 768px) {
    img {
      width: 75px;
      height: 75px;
    }
  }

  img:hover {
    opacity: 0.8;
  }
}


.navbar-toggle {
  margin-top: 12px;
}

.navbar-toggle:hover {
  background-color: #E6E6E6 !important;
}

.va-navbar {
  margin-top: 20px;

  a {
    line-height: 30px;
    color: #1a446c !important;
    font-size: 0.8em;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
  }

  a:hover {
    text-decoration: none;
    color: #1a446c !important;
    text-decoration: underline;
  }

  .cta {
    background-color: #31b0d5 !important;
    color: #ffffff !important;
    padding: 0px 20px;
    border-radius: 5px;
  }

  .cta.primary {
    background-color: #2baca6 !important;
  }

  .cta:hover {
    color: #ffffff !important;
    opacity:0.75;
    -webkit-transition:opacity 0.2s ease-in-out;
    transition:opacity 0.2s ease-in-out;
  }

  .btn-get-started,
  .btn-get-started:focus {
    background-color: #f5b400 !important;
    border-radius: 25px;
  }

  .btn-get-started:hover {
    background-color: #f5b400 !important;
    opacity: 0.7 !important;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
  }

}

.va-banner {
  background-color: $c-red !important;

  .va-banner-btn {
    background-color: #ffffff;
    color: $c-red !important;
    padding: 5px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: 10px;
    font-weight: 400;
  }

  .va-banner-btn:hover {
    color: $c-red !important;
    text-decoration: none;
  }
}

.va-hero {
  background-color: #839CAC;
  color: white;
  position: relative;
}

@media (min-width: 769px) {
  .va-hero {
    height: 500px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url('doctor-laptop.jpg');
    background-size: cover;

    .container {
      padding-top: 170px;

      h3 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .va-hero {
    .col-md-4 {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

#technical-benefits, #pricing {
  .subtitle {
    text-transform: uppercase;
    color: #31b0d5;
    letter-spacing: 3px;
    font-weight: 600;
  }
}

#pricing {
  .subtitle {
    color: #7a7c7f;
  }

  .excludes-vat {
    margin-top: 10px;
    font-size: 0.6em;
    display: block;
  }
}

.gray-bg { background: #f3f3f3; }

// Pricing


.pricing-card {
  min-height: 400px;
}

.planContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.plan {
  background: white;
  box-sizing: border-box;
  text-align: center;
  margin: 0 1em 1em 1em;
  border: 1px solid #7a7c7f;
}
.plan .titleContainer {
  background-color: #f3f3f3;
  padding: 1em;
}
.plan .titleContainer .title {
  font-size: 1.45em;
  text-transform: uppercase;
  color: #1abc9c;
  font-weight: 700;
}
.plan .infoContainer {
  padding: 1em;
  color: #2d3b48;
  box-sizing: border-box;
}
.plan .infoContainer .price {
  font-size: 1.35em;
  padding-bottom: 20px;
  margin-top: 0;
  display: inline-block;
}
.plan .infoContainer .price p {
  font-weight: 600;
  font-size: 1.35em;
  display: inline-block;
  margin: 0;
}
.plan .infoContainer .price span {
  font-size: 1.0125em;
  display: inline-block;
}
.plan .infoContainer .desc {
  border-bottom: 2px solid #f3f3f3;
  margin: 0 auto;
  width: 90%;
}
.plan .infoContainer .desc em {
  font-size: 1em;
  font-weight: 500;
}
.plan .infoContainer .features {
  font-size: 1em;
  list-style: none;
  padding-left: 0;
}
.plan .infoContainer .features li {
  padding: 0.5em;
}
.plan .infoContainer .selectPlan {
  border: 2px solid #1abc9c;
  padding: 0.75em 1em;
  border-radius: 2.5em;
  cursor: pointer;
  transition: all 0.25s;
  margin: 1em auto;
  box-sizing: border-box;
  max-width: 70%;
  display: block;
  font-weight: 700;
}
.plan .infoContainer .selectPlan:hover {
  background-color: #1abc9c;
  color: white;
}

@media screen and (max-width: 25em) {
  .planContainer {
    margin: 0;
  }
  .planContainer .plan {
    width: 100%;
    margin: 1em 0;
  }
}

.pricing-logo {
  width: 100px;
  height: 100px;

}

// Utility

.background-white {
  background-color: #FFFFFF;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-150 {
  padding-top: 150px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.width-100 {
  width: 100%;
}

// Vertical alignment

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.va-join-panel, .va-contact-panel {
  margin-top: 40px;
  margin-bottom: 40px;

  .code-input{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
