// Common Components: Sidebar
// -----------------------------------------------------

body {
  &.sidebar-open {
    overflow: hidden;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: clamp($sidebar-width, 70%, $sidebar-width);
  background-color: $c-white;
  z-index: get-zindex('layout', 'sidebar');
  visibility: hidden;
  transition: $sidebar-trans-duration $sidebar-trans-ease;

  &[data-sidebar-position='left'] {
    left: -$sidebar-width;
    box-shadow: 2px 1px 2px 2px $c-fade;

    &.open {
      left: 0;
    }
  }

  &[data-sidebar-position='right'] {
    right: -$sidebar-width;
    box-shadow: -2px 1px 2px 2px $c-fade;

    &.open {
      right: 0;
    }
  }

  &.open {
    @include visible;
  }

  &-close {
    @extend %btn-reset;
    padding: 4px;
    font-size: 18px;

    &:hover {
      color: $c-sidebar-green;
    }

    &:active {
      color: darken($c-sidebar-green, 10%);
    }
  }

  &-content {
    height: 100%;
    overflow: auto;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $sidebar-overlay-color;
    transition: $sidebar-trans-duration ease;
    z-index: get-zindex('layout', 'sidebar-overlay');
    @include invisible;

    &.open {
      @include visible;
    }
  }

  &-header-content {
    display: none;
  }

  .flash-messages {
    padding: 1.5rem 2.5rem 0 1.5rem;
  }
}

.messaging-templates-sidebar {
  width: 500px;
}

.api-communications-sidebar { 
  width: 500px;
}