/*
 * This is a manifest file that'll be compiled into hp.css and application.css, which will include all the files
 * listed below.
 *
 */

// Video Consultations

$screen-sm-min-video: 768px;

// Questionnaire

.video-consultation {
  .form-question-section {
    margin-top: 20px;
    padding-bottom: 20px;

    h3 { margin: 32px 0 16px; }
  }

  &.time-selection {
    #bureaucrat-form { margin-top: 20px; }
  }

  &#bureaucrat-form {
    .form-question,
    .form-question-style-only {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      @include default-mobile-text-size;

      ul { margin: 30px 0; }

      li + li { margin-top: 6px; }
    }
  }
}

@media (max-width: $screen-sm-min-video) {
  .video-consultation {
    .form-question-section {
      h3 { margin: 16px 0; }
    }
  }
}

.video-consultation.time-selection,
.video-consultation.day-selection,
.video-consultation.appointment-confirmation,
.video-consultation.find-doctor-sooner {
  .form-group { padding: 0; }

  .btn-qubico { margin: 4px 0; }

  .form-question {
    margin: 0 0 16px 0;
    padding: 0;
  }

  #choose-another-day-button,
  #find-doctor,
  #on-demand-section-buttons .btn-qubico,
  form#update-contact-number .btn-qubico,
  #tcands-agree-button,
  #vc-cancel-button {
    width: $vcwizard-button-width;

    @include xs-breakpoint {
      width: 100%;
    }
  }

  #find-doctor-question { margin: 34px 0 ; }

  #choose-another-day-question,
  #on-demand-section-buttons {
    margin-bottom: 34px;

    label {
      margin-top: 34px;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }

  #on-demand-section-buttons div {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  p.lead {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: $screen-sm-min-video) {
  .video-consultation.time-selection {
    #choose-another-day-question, #sooner {
      margin-bottom: 16px;
    }
  }
}

#patient-video-app {
  padding: 10px 0;

  #video-container {
    position: relative;
    margin: 0 auto;
    min-height: 300px;
  }

  #subscriber-container {
    position: relative;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 5px;

    #subscriber-info {
      p.lead {
        margin-top: 0px;
      }
    }

    #publisher-container {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1000;
      background-color: #2FBCB6;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 5px;

      #publisher-info {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;

        #publisher-info-heading {
          text-align: center;
          font-weight: bold;
          font-size: 1.2em;
        }

        #publisher-info-heading, #publisher-info-subheading {
          color: white;
        }
      }
    }
  }

  #subscriber-info {
    margin: 10% auto 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    color: #7a7c7f;

    p {
      text-align: center;
    }
  }

  #video-control-panel {
    padding: 10px 0px;
    background-color: #2FBCB6;
    border-radius: 0px 0px 5px 5px;
    margin: 0 auto;
  }
}

#video-container-wrapper {
  position: relative;
  margin: 0 auto;
}

#video-container-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize($color-danger, .2);
  z-index: 1020;
}

#video-container-overlay-feedback {
  position: absolute;
  top: 38%;
  left: 50%;
  width: 74%;
  color: #fff;
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);

  .fa-exclamation-triangle {
    display: block;
    font-size: 50px;
    margin-bottom: 20px;
  }
}

#video-app {
  #video-container {
    position: relative;

    #subscriber-container {
      position: relative;
      height: 100%;
      border-right: 1px solid #5bc0de;

      #publisher-container {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 1000;
      }
    }
  }

  #video-notes {
    margin: 0;
  }

  #notes .chat-panel {
    margin: 10px 0px;
  }

  #questionnaire {
    padding: 10px 0px;
    overflow: auto;
    height: 100%;
  }

  #patient-details {
    padding: 10px 0;
  }

  #video-toolbar {
    position: absolute;
    bottom: 0px;
    right: 15px;

    .panel {
      margin-bottom: 10px;
    }
  }

  #subscriber-info {
    margin: 140px auto 0 auto;
    position: absolute;
    left: 0;
    right: 0;

    p {
      text-align: center;
    }
  }
}

#video-app.container-fluid {
  padding-left: 0px;
}

#video-consultation-countdown {
  position: absolute;
  margin: 0 auto;
  bottom: 10px;
  height: 100px;
  min-width: 120px;
  left: 0;
  right: 0;
  display: none;
  text-align: center;
  z-index: 100;

  #vc-session-button-label {
    letter-spacing: 5px;
  }

  #vc-session-button-label.padded {
    margin-left: 5px;
  }
}

@media (max-width: $screen-sm-min-video) {
  #video-container-overlay-feedback {
    font-size: 18px;
    line-height: 23px;
  }

  #video-consultation-countdown {
    text-align: left;
    margin-left: 10px;
  }
}


.navbar-brand,
.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:focus {
  padding: 0 10px;
}

.video-consultation-capability {

  .browsers {
    margin-top: 20px;
  }

  .browser-logo {
    margin: 0 20px;
    height: 64px;
    width: 64px;
  }

  #video-consultation-not-supported-description, #video-consultation-supported-description, #vc-ios-not-supported-description {
    display: none;
  }
}

.select-options-wrapper {
  margin: 0 auto;
  text-align: center;

  @include breakpoint(min-width, $screen-tn-max) {
    max-width: 200px;
  }

  &.big {
    @include breakpoint(min-width, $screen-tn-max) {
      max-width: $vcwizard-button-width;
    }
  }

  .btn {
    width: 100%;

    + .btn { margin-top: 16px; }
  }

  .form-button { display: inline-block; }
}

table.table-pharmacy-selection {
  margin-top: 30px;
}

.video-consultation #pharmacy-selection-form select,
.video-consultation #pharmacy-selection-form input {
  display:inline-block;
  background:#fff;
  border-radius:5px;
  border: 1px solid #ccc;
  padding: 6px 12px;
  outline:none;
  box-shadow:0 4px 0 rgba(0,0,0,0.05);
  height:52px;
  font-size:18px;
  line-height:32px;
  color:#aaa;
  font-weight:400;
  vertical-align:top;
  width: 282px;
}

.video-consultation #pharmacy-selection-form input[disabled] {
  background-color: #F8F8F8;
}

// specific selector is here to override defaults
table.table-pharmacy-selection > tbody > tr > td {
  vertical-align: middle;
}

.video-consultation .pharmacy-selection .spinner {
  margin-bottom: 10px;
  margin-top: 10px;
}

.video-consultation .pharmacy-selection .switch-view-button,
.video-consultation .pharmacy-selection .use-preferred-pharmacy-btn {
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  margin: 40px 10px 20px;
  @include breakpoint(max-width, $screen-sm-min-video) {
    margin-top: 30px;
  }
}

.video-consultation .pharmacy-selection .use-preferred-pharmacy-btn {
  margin-top: 10px;
}

.video-consultation .pharmacy-selection .switch-view-button,
.video-consultation .pharmacy-selection #btn-skip-pharmacy-selection,
.video-consultation .pharmacy-selection .use-preferred-pharmacy-btn {
  width: 282px;
  @include breakpoint(max-width, $screen-sm-min-video) {
    font-size: 16px;
    line-height: normal;
  }
}

.video-consultation .pharmacy-selection #filterrific_search_query {
  padding-right: 40px;
}

.video-consultation .pharmacy-selection .form-group {
  display: inline-block;
}

.video-consultation .pharmacy-selection .search-by-county,
.video-consultation .pharmacy-selection .search-query-section {
  display: none;
}

.video-consultation .pharmacy-selection .form-group .form-control-feedback {
  position: absolute;
  right: 0;
  top: 8px;
  padding-right: 10px;
}

.video-consultation .pharmacy-selection .preferred-pharmacy-notification {
  background-color: $c-light-gray;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 10px;
}

.video-consultation .pharmacy-selection .preferred-pharmacy h3 {
  margin: 32px 0px;
}

.pharmacy-details-modal .modal-header h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pharmacy-details-modal .modal-body h3 {
  margin-top: 0px;
}

.pharmacy-details-modal .modal-body h4,
.pharmacy-details-modal .modal-body p {
  margin-bottom: 0px;
}

.pharmacy-details-modal .modal-footer {
  text-align: center;
}

.pharmacy-details-modal .modal-footer button {
  margin: 0;
}

.pharmacy-details-modal table tr td {
  padding-right: 10px;
}
