.container {
  font-family: $font-family-open-sans;

  &.devise-container {
    max-width: 528px;
    font-size: 1rem;
    font-family: $font-family-open-sans;

    .helper-links-container {
      a {
        @extend .hh-link !optional;
        @extend .hh-text-muted !optional;
        font-weight: 600;
      }
    }
  }

  @include breakpoint(max-width, bp-size('lg')) {
    &.login-container {
      flex-direction: column;

      .vertical-divider {
        display: none;
      }
    }
  }
}

.field_with_errors {
  .hh-form-control {
    @extend .hh-form-control-invalid !optional;
  }
}

.join_form_password.form-group-invalid {
  margin-bottom: 0;
}
