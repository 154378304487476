// Common: Banner
// -----------------------------------------------------

.banner {
  position: relative;
  padding: 18px;
  color: $c-white;
  font-weight: 700;
  text-align: center;
  background-color: $c-grey;

  &.green { background-color: $c-green; }
  &.blue { background-color: $c-blue; }
  &.pink { background-color: $c-pink; }
  &.red { background-color: $c-red; }

  p { margin: 0; }

  a {
    color: $c-white;

    &:hover,
    &:active,
    &:visited { color: $c-white; }
  }
}
