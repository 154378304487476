.hh-btn {
  .hh-pagination-sm & {
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    line-height: 1.3;
  }

  .hh-pagination-md & {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }

  .hh-pagination-lg & {
    padding: 0.6rem 1.8rem;
    font-size: 1.2rem;
    line-height: $font-line-height;
  }
}

.page-item {
  .hh-btn {
    @extend .hh-btn-light;
  }

  &.active {
    .hh-btn {
      @extend .hh-btn-info;
    }
  }

  &.disabled {
    .hh-btn {
      @extend .hh-btn-disabled;
    }
  }
}
