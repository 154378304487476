#main-nav {
  .navbar-toggle {
    display: block;

    @include breakpoint(min-width, $screen-md-min) {
      display: none;
    }
  }

  .navbar-header {
    justify-content: space-between;

    @include breakpoint(max-width, $screen-md-min) {
      float: none;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

#main-menu {
  list-style-type: none;
  padding: 0;
  display: inline !important;
  margin-bottom: 15px !important;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      line-height: 30px !important;
      margin-right: 5px;
      margin-top: auto;
      padding: 3px 20px;
    }
  }
}

@media (min-width: 992px) {
  #site-nav {
    height: 100% !important;
    display: flex !important;
    align-content: center;
    justify-content: flex-end;
  }
}

@media (max-width: 992px) {
  #site-nav {
    overflow-y: unset;
  }

  #main-menu {
    list-style-type: none;
    display: block !important;
    width: 100%;
    margin: unset;

    li {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      padding: 0 20px;

      a {
        width: 100%;
        padding: 5px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .container {
    width: 100% !important;
  }
}

.navbar-header {
  justify-content: space-between;
}
