// Webdoctor: Buttons
// -----------------------------------------------------

@import './common/buttons';

.btn {
  @extend %bootstrap-btn;
}
.btn-primary {
  @extend %bootstrap-btn-primary;
}
.btn-success {
  @extend %bootstrap-btn-success;
}
.btn-info {
  @extend %bootstrap-btn-info;
}
.btn-warning {
  @extend %bootstrap-btn-warning;
}
.btn-danger {
  @extend %bootstrap-btn-danger;
}
.btn-default {
  @extend %bootstrap-btn-default;
}

.btn-qubico {
  @extend %btn-qubico;
  @include btn-states($bg-color: $c-green, $bg-color-active: $c-blue);

  @include sm-breakpoint {
    min-width: 100px;
    font-size: 13px;
    line-height: 21px;
  }

  @include tn-breakpoint {
    display: block;
    min-width: 100%;
  }

  &.white {
    @extend %btn-qubico-white;
  }

  &.white-bordered {
    @extend %btn-qubico-white-bordered;
  }

  &.primary {
    @extend %btn-qubico-primary;
  }

  &.info,
  &.selected {
    @extend %btn-qubico-info;
  }

  &.warning {
    @extend %btn-qubico-warning;
  }

  &.danger {
    @extend %btn-qubico-danger;
  }

  &.coupon {
    @extend %btn-qubico-coupon;
  }

  &.success {
    @extend %btn-qubico-success;
  }

  &.btn-sm {
    font-size: 11px;
  }
}

.btn-treatment {
  min-width: 95%;
}

.btn-apply {
  width: 200px;
}

.btn-big,
.btn-female,
.btn-male,
.btn-renew,
.btn-video-consultation,
.btn-sti,
.btn-home {
  @extend %btn-qubico;
  text-align: left;

  .doctors-and-treatments-row &,
  .dashboard-list &,
  .unauthenticated-list & {
    margin: 20px 0 10px;
    padding: 15px 30px;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-label {
  @include btn-states($bg-color: $c-green, $bg-color-hover: $c-blue);
}

.btn-female {
  @include btn-states($bg-color: $c-pink, $bg-color-active: $c-green);
}

.btn-male {
  @include btn-states($bg-color: $c-blue, $bg-color-active: $c-green);
}

.btn-home {
  @include btn-states($bg-color: $btn-gray-color, $bg-color-active: $c-green);
}

.btn-register-clinic {
  @include btn-states($bg-color: $c-red, $bg-color-active: $c-red);
}

.btn-female,
.btn-male,
.btn-home {
  .white {
    text-decoration: none;
    border: 2px solid #fff;
    background: transparent;
    box-shadow: none;
    transition: 0.06s ease-out;

    &:hover,
    &:focus {
      top: 0;
      color: $btn-primary-color;
      text-decoration: none;
      background: #fff;
    }
  }
}

.btn-treatment-cta {
  opacity: 0.8;
  min-width: 80%;
  padding: 15px 30px;
  font-size: 11px;
  display: inline-block;
  margin: 10px 0;
  font-weight: 200;
  outline: none;
  position: relative;
  box-shadow: none;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.btn-treatment-video-cta {
  color: #fff;
  opacity: 0.4;
  background: $background-color;

  &:hover {
    color: #fff;
    opacity: 1;
    background: $background-color;
  }
}

.btn-qubico[disabled],
.btn-qubico.disabled {
  @extend .btn, .disabled;
  cursor: not-allowed;
}

.btn-calendar {
  border-width: 2px;
  box-shadow: none !important;

  &.btn-disabled {
    border-color: #dedede;
    color: #dedede;
    border-radius: 100px;
  }

  &.btn-default {
    border-color: #b7b7b7;
    background-color: $c-white;
    color: #262326;
    font-weight: 300;
    border-radius: 100px;

    &:hover,
    &.active,
    &.selected,
    &.active:hover {
      border-color: $c-green;
      background-color: $c-green;
      color: #fff;
    }
  }

  &.btn-hours {
    width: 100px;
    padding: 5px;
    border-color: #b7b7b7;
    background-color: $c-white;
    color: #262326;
    font-weight: 300;
    border-radius: 100px;

    &:hover,
    &.active,
    &.selected,
    &.active:hover {
      border-color: $c-green;
      background-color: $c-green;
      color: #fff;
    }
  }
}

.btn-questionnaire-button {
  border-width: 2px;
  box-shadow: none !important;
  width: 100px;
  padding: 5px;
  border-color: #b7b7b7;
  background-color: $c-white;
  color: #262326;
  font-weight: 300;
  border-radius: 100px;
}

.btn-questionnaire-button:hover {
  background-color: #2fbcb6;
  color: $c-white;
}

.btn-next-patient-booking {
  background-color: #31b0d5 !important;
  color: $c-white !important;
  padding: 0px 20px;
  border-radius: 5px;
  border: none;
  margin: 2.5px 0;

  &.btn-yes, &.btn-no {
    background-color: #F2F4F5 !important;
    border-color: #F2F4F5  !important;
    color: #343A40  !important;
  }
}

.btn-next-patient-booking.btn-yes, .btn-next-patient-booking.btn-no {
  background-color: #F2F4F5 !important;
  border-color: #F2F4F5  !important;
  color: #343A40  !important;
}

.submit-button {
  padding: 24px;
}

.btn-use-my-location {
  background-color: #31b0d5;
  padding: 2px 6px;
  margin: 1em 0;
  box-shadow: 0 2px 3px #00000018;
}

.card .card-link {
  margin-right: 10px !important;

  .btn,
  .btn-booking {
    padding: 8px 40px;
    white-space: normal;
    line-height: 27px !important;

    @media only screen and (max-width: 400px) {
      padding: 4px 20px;
    }
  }
}
