@mixin create-class-by-map($map, $prepend-class) {
  @each $val in $map {
    .hh-#{$prepend-class}-#{$val} {
      color: theme-color($val);
    }
  }
}

@mixin link-color($color, $color-hover) {
  &:hover {
    color: $color-hover;
    border-bottom-color: $color-hover;
  }
}

@mixin add-link-colors($map: $theme-colors-set) {
  @each $val in $map {
    &-#{$val} {
      @include link-color($color: theme-color($val), $color-hover: theme-color($val));
    }
  }
}

// Headings
.hh-h1,
.hh-h2,
.hh-h3,
.hh-h4,
.hh-h5,
.hh-h6 {
  margin-bottom: 1rem;
  color: theme-color();
  font-family: $font-family-open-sans;
  font-weight: 600;
  line-height: $font-line-height;
}

.hh-h1 {
  font-size: 2rem;
}

.hh-h2 {
  font-size: 1.8rem;
}

.hh-h3 {
  font-size: 1.6rem;
}

.hh-h4 {
  font-size: 1.4rem;
}

.hh-h5 {
  font-size: 1.2rem;
}

.hh-h6 {
  font-size: 1rem;
}

// Link
.hh-link {
  color: theme-color('primary');
  line-height: 1.4;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.06s;

  &:hover {
    color: theme-color('secondary');
    border-bottom-color: inherit;
    text-decoration: none;
  }

  @include add-link-colors;

  &-inverted {
    color: theme-color('secondary');

    &:hover {
      color: theme-color();
      border-bottom-color: inherit;
    }
  }

  span + .icon {
    margin-left: 0.3rem;
  }

  .icon + span {
    margin-left: 0.3rem;
  }
}

// Text / Paragraph
.hh-text {
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.5em;
}

.hh-text-muted {
  color: color-gray('500');
}

.hh-text-sm {
  font-size: 0.8rem;
  line-height: 1.4;
}

.hh-text-md {
  font-size: 1.2rem;
}

.hh-text-lg {
  font-size: 1.6rem;
  line-height: 1.8;
}

.hh-text-center {
  text-align: center;
}

.hh-text-left {
  text-align: left;
}

.hh-text-right {
  text-align: right;
}

.hh-text-uppercase {
  text-transform: uppercase;
}

.hh-text-lowercase {
  text-transform: lowercase;
}

.hh-text-capitalize {
  text-transform: capitalize;
}

.hh-text-light {
  font-weight: 300;
}

.hh-text-regular {
  font-weight: 400;
}

.hh-text-medium {
  font-weight: 500;
}

.hh-text-semi-bold {
  font-weight: 600;
}

.hh-text-bold {
  font-weight: 700;
}

@include create-class-by-map($theme-colors-set, 'color');
