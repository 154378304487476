.alexandria {
  padding-bottom: 50px;
}

.search-row {
  padding: 50px 0;
}

.container.alexandria-categories {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 50px;
}

.category-row, .search-results-row {
  font-size: 1.2em;
  margin-top: 20px;

  li {
    margin-top: 0.5em;
  }
}

.article-body {
  font-size: 1.2em;
}