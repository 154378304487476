#disabled-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0; 
  background-color: transparentize(white, .25); 
  transition: 2s;
}

#disabled-overlay-icon {
  position: absolute;
  top: 100px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  transition: 0.6s;
}
