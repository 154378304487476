.fake-attached-file-wrapper {
  display: none;
}

.fake-file {
  position: relative;
  cursor: pointer;
}

.fake-file-input {
  @include invisible;
  @include size(100%);
  position: absolute;
  z-index: get-zindex('base');
}

.fake-file-label {
  font-size: 0.95rem;
  font-weight: 400;
  font-style: italic;
  z-index: get-zindex('above');
}

.fake-file-remove-all {
  list-style: none;
}
