$video-user-header-height: 30px;
$video-user-header-trans-duration: 0.45s;
$video-user-header-trans-ease: cubic-bezier(0.25, 0.46, 0.45, 0.94);

$btn-rounded-color: darken($c-grey, 25%);
$btn-rounded-color-hover: darken($c-grey, 30%);
$btn-rounded-accent-color-hover: darken($c-red, 10%);

#video-app {
  margin: 15px auto;
  border-radius: 5px;
  padding: 0;
  position: relative;
}

.control-panel {
  .btn-rounded {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 60px;
    height: 60px;
    margin: 10px;
    font-size: 26px;
    color: $c-white;
    border-radius: 100%;
    text-decoration: none;
    background-color: $btn-rounded-color;
    transition: 0.06s;

    &:hover:not([disabled]) {
      text-decoration: none;
      background-color: $btn-rounded-color-hover;
    }

    &[disabled] {
      color: transparentize($c-white, 0.4);
    }
  }

  .btn {
    &-accent {
      background-color: $c-red;

      &:hover:not([disabled]) {
        background-color: $btn-rounded-accent-color-hover;
      }
    }

    &-hangup {
      transform: rotate(135deg);
    }

    &-strikethrough {
      &::before {
        content: '';
        position: absolute;
        top: 43%;
        left: 50%;
        width: 70%;
        height: 2px;
        background-color: #ffffff;
        border-radius: 10px;
        transform: translate(-50%, -50%) rotate(30deg);
        box-shadow: 1px 0 1px 1px $c-red;
      }

      &:hover:not([disabled])::before {
        box-shadow: 1px 0 1px 1px $btn-rounded-accent-color-hover;
      }

      &[disabled]::before {
        background-color: transparentize($c-white, 0.4);
      }
    }
  }
}

.video-view-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  .info {
    text-align: center;
    padding: 10% 10% 0;
    font-size: 120%;
  }

  .control-panel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
  }
}

#video-view {
  position: relative;
  width: 100%;
  height: 100%;

  .btn:active:focus {
    outline: none;
  }
}

#controls-and-chat-positioner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

#controls-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

#text-chat-container {
  bottom: 0;
  position: fixed;
  right: 0;
  width: 380px;
  margin: 0 10px 10px 0;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 -2px 0 0 $c-primary;
  }

  .panel {
    border: 0;
    background-color: transparent;
    margin: 0;
  }

  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $c-white;
    cursor: pointer;
    padding: 15px;
    font-size: 15px;
    font-weight: 600;
    background-color: $c-primary;
    border-radius: 4px;

    .btn {
      background: none;
      box-shadow: none;

      &:hover,
      &:active,
      &:focus {
        color: $c-white;
      }
    }
  }

  .panel-body {
    padding: 0;
    height: 55vh;
  }
}

#video-control {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

#video-info-panel {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 0;
  text-align: center;
}

#video-user-container {
  position: absolute;
  right: 10px;
  top: 10px;
  min-width: 300px;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;
  transition: $video-user-header-trans-duration $video-user-header-trans-ease;

  &.video-user-closed {
    height: $video-user-header-height !important;
  }
}

#video-hardware-check {
  button {
    border: none;
  }
}

#video-hardware-denied {
  background-color: $light-color;

  .fa-ban {
    color: $c-red;
  }

  a.btn {
    background-color: $c-red;
    border: none;
  }
}

#video-user-header {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $video-user-header-height;
  padding: 0 10px;
  font-size: 15px;
  line-height: 34px;
  color: $c-white;
  text-align: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);

  .icon-stream {
    @include invisible;
    color: $c-red;
    font-size: 18px;
    transition: $video-user-header-trans-duration;

    .video-user-closed & {
      animation: 1.35s stream-blink ease infinite;
    }
  }
}

.video-user-title {
  flex-grow: 1;
}

.video-user-control {
  padding: 0;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  .icon-maximize {
    display: none;
  }

  .video-user-closed & {
    .icon-maximize {
      display: block;
    }
    .icon-minimize {
      display: none;
    }
  }
}

#spinner-overlay {
  z-index: 4000;
  background-color: $light-color;

  .spinner {
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
  }
}

@media (max-width: 1200px) {
  #text-chat-container {
    width: 200px;
  }
}

@media (max-width: 800px) {
  #video-control {
    flex-flow: column;
    align-items: flex-start;
  }

  #connect-button {
    align-self: center;
  }

  #text-chat-container {
    width: 380px;
  }
}

@media (max-width: 500px) {
  #text-chat-container {
    width: 200px;
  }
}

@keyframes stream-blink {
  from,
  to {
    @include invisible;
  }

  50% {
    @include visible;
  }
}

.modal-backdrop {
  background-color: rgba(0,0,0,.0001) !important;
}

.OT_video-element {
  height: 100%;
  width: 100%;
}
