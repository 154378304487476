#form.new_join_form .invalid-feedback {
  color: red;
}
.inline-error-container {
  .error_container {
    display: block;
    margin: 10% auto 0 auto;
    width: 40%;
    text-align: center;
  }
  .error_container .oops {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .error_container .description {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  .error_container .status {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .error_container .links ul {
    list-style-type: none;
    padding-inline-start: 0;
    line-height: 2;
  }
  .error_container .picture {
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }
}