.main-nav {
  border-bottom: 1px solid color-gray('300');
  box-shadow: 0 0 3px color-gray('300');
  z-index: get-zindex('layout', 'main-nav');

  &-wrapper {
    display: flex;
    align-items: center;
    max-width: 1170px;
    padding: 14px 20px;
    margin: 0 auto;

    @include breakpoint(min-width, bp-size('md')) {
      justify-content: space-between;
    }
  }
}

.main-nav-toggle-wrapper {
  display: flex;
  align-items: center;

  .main-nav-logo {
    position: relative;
    top: -3px;

    img {
      width: 150px;
    }
  }

  .main-nav-toggle {
    padding: 10px 10px 10px 0;
    margin-right: 6px;
    font-size: 1.2rem;

    @include breakpoint(min-width, bp-size('md')) {
      display: none;
    }
  }

  .main-sidenav & {
    padding: 14px 20px;
  }
}

.main-sidenav {
  .sidebar-header {
    border-bottom: 1px solid color-gray('300');
    box-shadow: 0 0 3px color-gray('300');
  }
}

.main-menu {
  display: flex;
  flex-direction: column;
  font-family: $font-family-lato;

  a:not(.hh-btn) {
    padding: 0 4px;
    margin: 0 10px;
    font-size: 0.95rem;
    font-weight: 600;
    color: theme-color('primary');
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: theme-color('primary');
    }
  }

  .hh-btn {
    justify-content: center;
    color: theme-color('primary');

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  .main-nav & {
    display: none;

    @include breakpoint(min-width, bp-size('md')) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .main-sidenav & {
    padding: 40px 20px;

    a:not(.hh-btn) {
      margin: 0;
      padding: 0.5rem 0;
    }

    .featured {
      margin-top: 30px;
      width: 100%;
    }
  }
}
