@mixin create-color-class($map: $theme-colors-set) {
  @each $val in $map {
    $text-color: theme-color($val, 'text');

    @if not $text-color {
      $text-color: color('white');
    }

    &-#{$val} {
      color: $text-color;
      background-color: theme-color($val);
    }
  }
}

%default-size {
  width: $initials-size;
  height: $initials-size;
  font-size: 1.2rem;
}

.hh-initials {
  @extend %default-size;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 600;
  font-family: $font-family-lato;
  border-radius: 100%;

  @include create-color-class;

  &-sm {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  &-md {
    @extend %default-size;
  }

  &-lg {
    width: 60px;
    height: 60px;
    font-size: 1.4rem;
  }
}
