// Common: Alert
// -----------------------------------------------------

.alert-danger-special {
  color: white;
  background-color: #c5272d;
  text-transform: uppercase;

  span,
  .btn { vertical-align: middle; }

  span { padding-right: 10px; }

  @include sm-breakpoint {
    .btn { margin-top: 16px; }
  }
}

.row-vertical-align {
  display: flex;
  align-items: center;
}

.custom-messages table tr td:first-child {
  padding-right: 10px;
}

.custom-messages {
  margin-top: 15px;
  font-size: 16px;
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
