.patient-cancellation-form {
  padding: 100px 0;

  .cancellation-form-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .cancellation-reasons {
      width: fit-content;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
      margin-bottom: 20px;

      input {
        margin-right: 20px;
      }

      label {
        font-weight: 300;
      }

      .custom_reason {
        width: 100%;
        height: 80px;
        resize: both;
        box-sizing: border-box;
      }
    }
  }
}
