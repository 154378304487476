// Unsuported Check
// --------------------------------------------

.unsupported-footer { margin-top: 40px; }

.browsers-logo-wrapper { padding: 20px 0; }

.browser-logo {
  display: inline-block;
  @include square(64px);
  background-size: 100% 100%;

  &.browser-chrome { background-image: asset-url('browsers/chrome.png'); }
  &.browser-firefox { background-image: asset-url('browsers/firefox.png'); }
  &.browser-opera { background-image: asset-url('browsers/opera.png'); }

  @include retina2x() {
    &.browser-chrome { background-image: asset-url('browsers/chrome@2x.png'); }
    &.browser-firefox { background-image: asset-url('browsers/firefox@2x.png'); }
    &.browser-opera { background-image: asset-url('browsers/opera@2x.png'); }
  }

  + .browser-logo { margin-left: 8px; }
}

.unsupported-header {
  @include xs-breakpoint {
    margin: 0;
  }
}

