// Webdoctor - Mixins
// --------------------------------------------

// Size
@mixin square($size) {
  width: $size;
  height: $size;
}

// Arrow border icons
@mixin icon-arrow-top($color, $size) {
  border-style: solid;
  border-color: transparent transparent $color transparent;
  border-width: 0 $size $size $size;
}

@mixin icon-arrow-bottom($color, $size) {
  border-style: solid;
  border-color: $color transparent transparent transparent;
  border-width: $size $size 0 $size;
}

@mixin icon-arrow-left($color, $size) {
  border-style: solid;
  border-color: transparent $color transparent transparent;
  border-width: $size $size $size 0;
}

@mixin icon-arrow-right($color, $size) {
  border-style: solid;
  border-color: transparent transparent transparent $color;
  border-width: $size 0 $size $size;
}

// Border radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

// Breakpoint
@mixin breakpoint($feature, $value, $feature2: null, $value2: null) {
  // Set global device param
  $media: only screen;

  @if $feature2 {
    @media #{$media} and ($feature: $value) and ($feature2: $value2) {
      @content;
    }
  } @else {
    @media #{$media} and ($feature: $value) {
      @content;
    }
  }
}

// Mobile Breakpoint
// Small screen / tablet / phone
@mixin sm-breakpoint($feature: max-width) {
  @include breakpoint($feature, $break-mobile) {
    @content;
  }
}

// Extra small screen / phone
@mixin xs-breakpoint($feature: max-width) {
  @include breakpoint($feature, $screen-xs-max) {
    @content;
  }
}

// Tiny screen / phone
@mixin tn-breakpoint($feature: max-width) {
  @include breakpoint($feature, $screen-tn-max) {
    @content;
  }
}

// Is Webview
@mixin is-webview($parent: null) {
  @if $parent != null {
    &.is-webview {
      @content;
    }
  } @else {
    .is-webview & {
      @content;
    }
  }
}

// Is Webview and Mobile Web
@mixin is-mobileview() {
  @include breakpoint(max-width, $break-mobile) {
    @content;
  }

  @include is-webview {
    @content;
  }
}

// Mobile Touch devices
@mixin touch-devices() {
  .touch & {
    @content;
  }
}

// Mobile no Touch devices
@mixin no-touch-devices() {
  .no-touch & {
    @content;
  }
}

// Retina display
@mixin retina() {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 1.25/1),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    @content;
  }
}

// Buttons hover / focus / active
@mixin btn-states(
  $bg-color,
  $bg-color-hover: darken($bg-color, 4%),
  $bg-color-active: darken($bg-color-hover, 6%),
  $c-text: #fff,
  $c-text-hover: $c-text,
  $c-text-active: $c-text,
  $border-type: solid,
  $border-size: null,
  $border-color: $bg-color,
  $important: null
) {
  color: $c-text $important;
  background-color: $bg-color $important;

  @if $border-size {
    border: $border-type $border-size $border-color $important;
  }

  &:hover,
  &:focus {
    color: $c-text-hover $important;
  }

  &:hover {
    background-color: $bg-color-hover $important;

    @if $border-size {
      border: $border-type $border-size $border-color $important;
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    color: $c-text-active $important;
    background-color: $bg-color-active $important;

    @if $border-size {
      border: $border-type $border-size $border-color $important;
    }
  }

  @include btn-disabled($bg-color, $c-text);
}

// Buttons Disabled
@mixin btn-disabled($bg-color, $c-text: #fff, $border-color: $bg-color, $important: null) {
  &[disabled],
  &.disabled {
    &:hover,
    &:focus,
    &:active {
      color: $c-text $important;
      background-color: $bg-color $important;
      box-shadow: none $important;
      border-color: $border-color $important;
    }
  }
}

// Retina display
@mixin retina2x() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    @content;
  }
}

@mixin retina3x() {
  @media only screen and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx) {
    @content;
  }
}

// Retina
@mixin retina-icons($name, $size, $sprite: null) {
  @if $sprite == null {
    @include square($size);
    background-image: url(#{$name}.png);
  }

  @include retina2x {
    background-image: url(#{$name}@2x.png);
    background-size: $size $size;
  }

  @include retina3x {
    background-image: url(#{$name}@3x.png);
    background-size: $size $size;
  }
}

// Icons Sprite
@mixin sprite-icons($sprite, $sprite-width) {
  @each $icon in $sprite {
    $icon-name: nth($icon, 10);
    .#{$icon-name} {
      @include sprite($icon);
      @include retina-icons('sprite', $sprite-width, 'is-sprite');
    }
  }
}

// User Select Vendors
@mixin user-selection($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

// Zindex - Function
@function zindex($layer) {
  @if not map-has-key($zindex, $layer) {
    @warn 'No layer found for \`#{$layer}\` in $z-layers map. Property omitted.';
  }

  @return map-get($zindex, $layer);
}

// Text
@mixin default-mobile-text-size() {
  @include breakpoint(max-width, $break-mobile) {
    font-size: 16px;
    line-height: 21px;
    background-color: #ffffff;
  }
}

// Visibility
@mixin invisible() {
  opacity: 0;
  visibility: hidden;
}

@mixin visible() {
  opacity: 1;
  visibility: visible;
}
