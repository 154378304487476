// Common: Buttons
// -----------------------------------------------------

// Bootstrap Classes Placeholders
%bootstrap-btn { @extend .btn; }
%bootstrap-btn-primary { @extend .btn-primary; }
%bootstrap-btn-success { @extend .btn-success; }
%bootstrap-btn-info { @extend .btn-info; }
%bootstrap-btn-warning { @extend .btn-warning; }
%bootstrap-btn-danger { @extend .btn-danger; }
%bootstrap-btn-default { @extend .btn-default; }
%bootstrap-btn-disabled { @extend .btn, .disabled; }

%btn-qubico {
  position: relative;
  display: inline-block;
  padding: 10px 10px;
  margin: 20px 0;
  min-width: $btn-min-width;
  min-height: $btn-min-height;
  font-family: inherit;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  outline: none;
  border: none;
  border-radius: 4px;
  vertical-align: top;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
    box-shadow: none;
  }

  &:active {
    &:focus { outline: none; }
  }

  &.btn-sm { padding: 5px 10px; }

  &.no-margin { margin: 0; }

  &.sm-top-margin { margin: 5px 0 0 0; }

  &.sm-margin { margin: 5px 0 0; }
}

%btn-qubico-white {
  @include btn-states(
    $bg-color: #fff,
    $bg-color-hover: saturate($c-green, 20%),
    $c-text: $c-green,
    $c-text-hover: #fff
  );
}

%btn-qubico-white-bordered {
  @include btn-states($bg-color: #fff, $c-text: $c-green);
  border: 1px solid $c-green;

  &:hover,
  &:focus,
  &:active { border-color: $c-green; }
}

%btn-qubico-default {
  @include btn-states($bg-color: $btn-default-color, $c-text: $text-color);
  @include btn-disabled($btn-default-color);
}

%btn-qubico-primary {
  @include btn-states($bg-color: $btn-primary-color);
  @include btn-disabled($btn-primary-color);
}

%btn-qubico-success {
  @include btn-states($bg-color: $btn-success-color);
  @include btn-disabled($btn-success-color);
}

%btn-qubico-info {
  @include btn-states($bg-color: $btn-info-color);
  @include btn-disabled($btn-info-color);
}

%btn-qubico-warning {
  @include btn-states($bg-color: $btn-warning-color);
  @include btn-disabled($btn-warning-color);
}

%btn-qubico-danger {
  @include btn-states($bg-color: $btn-danger-color);
  @include btn-disabled($btn-danger-color);
}

%btn-qubico-disabled {
  @extend %bootstrap-btn-disabled;
}

%btn-qubico-coupon {
  opacity: 0.75;
  padding: 5px 5px;
  margin: 10px 0;
}
