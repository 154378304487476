// Common: Variables
// -----------------------------------------------------

// App classname that will prepend all classes
$app-namespace: '';

// Colors
$c-white: #ffffff;
$c-fade: rgba(0, 0, 0, 0.1);
$c-pink: #ff84c5;
$c-blue: #489ff0;
$c-light-blue: #31b0d5;
$c-red: #d9534f;
$c-blue-dark: #3677b3;
$c-grey: #a0a0a0;
$c-light-gray: lighten(#a0a0a0, 30%);
$c-cyan: #31bdb6;
$c-green: #2fbcb6;
$c-sidebar-green: #1cbb84;
$c-primary: $c-green;

$light-color: #f5f5f5;
$text-color: #7a7c7f;
$text-highlight-color: #4e5961;
$background-color: $c-green;
$background-dark-color: #4e5961;
$color-danger: $c-red;
$form-bg-color: #ffffff;
$btn-primary-color: $c-green;
$btn-success-color: #5cb85c;
$btn-info-color: #5bc0de;
$btn-default-color: $c-light-gray;
$btn-warning-color: #f0ad4e;
$btn-danger-color: #d9534f;
$link-hover-color: #63ccc8;
$btn-gray-color: $c-grey;
$btn-upgrade-primary: #ffd700;
$btn-upgrade-text-color: #fffbe5;

// Forms / Questionnaires
$form-question-color: #38e1da;
$form-question-selected-color: #2f8cff;
$space-left: 20px;
$form-questions-space: $space-left;
$form-small-inputs-width: 180px;

//Priorities
$highest-priority-color: #ff0000;
$high-priority-color: #ff8c00;
$medium-priority-color: #ffd700;
$lower-priority-color: #90ee90;
$lowest-priority-color: #008000;
$move-to-top-button-color: #1cbb84;
$priority-selector-border-color: #a0a0a0;

// Services colors
$service-red: #ff0000;
$service-yellow: #ffff00;
$service-blue: #0000ff;
$service-orange: #ffa500;
$service-purple: #cc00cc;
$service-green: #00b300;
$service-violet: #ee82ee;
$service-vermilion: #e0381f;
$service-magenta: #b300b3;
$service-teal: #00cccc;
$service-amber: #ffc200;
$service-chartreuse: #7fff00;
$service-light-red: #ff6666;
$service-light-yellow: #ffffb3;
$service-light-blue: #6666ff;
$service-light-orange: #ffc966;
$service-light-purple: #ff33ff;
$service-light-green: #1aff1a;
$service-light-violet: #f9d2f9;
$service-light-vermilion: #ed8878;
$service-light-magenta: #ff1aff;
$service-light-teal: #33ffff;
$service-light-amber: #ffdb66;
$service-light-chartreuse: #b3ff66;
$service-dark-red: #990000;
$service-dark-yellow: #999900;
$service-dark-orange: #996300;
$service-dark-violet: #e01fe0;
$service-dark-vermilion: #872212;
$service-dark-teal: #006666;
$service-dark-amber: #997500;
$service-dark-chartreuse: #4d9900;
$service-default: #ffffff;

//Services text colours
$service-text-white: #ffffff;
$service-text-black: #000000;
$service-text-brown: #A52A2A;
$service-text-default: #808080;

// Responsive: Breakpoints
$screen-tn-max: 320px;
$screen-xs-max: 540px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$break-mobile: $screen-sm-min;
$card-breakpoint: 650px;

// Others
$vcwizard-button-width: 270px;
$small-margin: 25px;
$tiny-margin: 10px;
$default-margin: 10px;
$default-padding: 20px;
$navbar-height: 0;
$navbar-sm-height: 0;
$default-font-size: 18px;
$default-line-height: 20px;
$btn-min-width: 150px;
$btn-min-height: 41px;
$btn-min-height: 41px;
$order-length: 10;

// A map containing all z-index layers
// In case of having duplication of the same value
// the first occurrence will be used (top to bottom)
$z-indexes-map: (
  'under': -1,
  'base': 0,
  'above': 1,
  'dialog': 100,
  'alert': 200
);

// To toggle warn or error messages
$z-indexes-log: (
  'used-key': true,
  'used-value': true,
  'less-than': false,
  'error': true
);

// Sidebar
$sidebar-width: 300px;
$sidebar-trans-duration: 0.25s;
$sidebar-trans-ease: ease-out;
$sidebar-overlay-color: rgba(0, 0, 0, 0.46);

// Questionnaires
$font-line-height: 1.7;

$fa-font-path: '@fortawesome/fontawesome-pro/webfonts';
