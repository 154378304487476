// Common: Forms
// -----------------------------------------------------

.form-group {
  @extend .clearfix;
  position: relative;
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}

textarea.form-control {
  height: 150px;
}

input.form-control.number {
  width: 100px;
  text-align: center;
  margin-left: 20px;
}

span.input-group-addon {
  min-width: 50px;
  font-size: 14px;
  text-transform: uppercase;
}

input[type='date'] {
  @include is-webview {
    height: auto !important;
  }
}

input.mobile-friendly[type='date'] {
  @include is-webview {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }
}

input.mobile-friendly[type='date']::before {
  @include is-webview {
    content: attr(placeholder) !important;
    color: #999999;
    margin-right: 0.5em;
  }
}

input.mobile-friendly[type='date']:focus::before,
input.mobile-friendly[type='date']:valid::before {
  @include is-webview {
    content: '';
  }
}

// Input File / Bootstrap Filestyle
.bootstrap-filestyle {
  .icon-span-filestyle {
    margin-right: 6px;
  }
}
