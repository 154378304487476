#nps_survey {
  label.btn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    border-radius: 100px;
    font-size: 16px;
    width: calc(6*(var(--sjs-base-unit, var(--base-unit, 8px))));
    height: calc(6*(var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  }

  .sv_q_rating_min_text,
  .sv_q_rating_max_text {
    color: $c-grey;
    margin: 10px;
  }

  .sv_q_required_text {
    color: $c-red;
  }

  h5 {
    font-weight: bold;
    margin: 10px;
  }
}
