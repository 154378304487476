// Views: Speed Test
// -----------------------------------------------------

.speedtest-page {
  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .fa-spinner,
  .fa-check-circle {
    font-size: 36px;
    line-height: 40px;
  }
}

.speedtest-wrapper {
  .webdoc-page & { padding: 40px 0; }
}

.laya-content-header {
  + .speedtest-wrapper { padding: 0; }
}

.speedtest-progress { margin: 30px 0; }

.speedtest-progress-text {
  padding-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.speedtest-finished {
  .btn { margin-top: 10px; }
}
