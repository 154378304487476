@import "intl-tel-input/src/css/intlTelInput.scss";
$flagsImagePath: "intl-tel-input/build/img/";

.iti { width: 100%; }

.iti__flag {
  background-image: url("#{$flagsImagePath}#{$flagsImageName}.#{$flagsImageExtension}");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("#{$flagsImagePath}#{$flagsImageName}@2x.#{$flagsImageExtension}");
  }
}
