@import '../patients/variables';

.sq-field {
  opacity: 100 !important;
}

.sq-item-wrapper.selected label {
  background-color: #21a508 !important;
  border-color: #21a508 !important;
}

#sq-back-button {
  border-color: #f2f4f5 !important;
  background-color: #f2f4f5 !important;
  color: #9a9a9a !important;
  line-height: 1.4 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  padding: 0.6rem 1.4rem 0.46rem !important;
  font-family: $font-family-montserrat;

  &:hover {
    color: $text-color !important;
  }
}

#sq-next-button {
  border-color: $c-light-blue !important;
  background-color: $c-light-blue !important;
  color: $c-white !important;
  line-height: 1.4 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  padding: 0.6rem 1.4rem 0.46rem !important;
  font-family: $font-family-montserrat;
}

/* Sections labels / Page title */
.sq-section-form {
  margin-top: 30px;
  text-align: left;

  h1 {
    @extend .hh-h4 !optional;
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    line-height: 1.7 !important;
    letter-spacing: .02em !important;
    text-align: center;
  }

  h1.sq-section-label {
    text-align: left;
    color: $color-gray-800;
    border-color: $btn-info-color !important;
    line-height: 26px !important;
    font-size: 20px !important;
  }

  .sq-label {
    font-weight: normal !important;

    .sq-question-no {
      font-size: 0.9375rem !important;
    }
  }

  .sq-choices-wrapper {
    display: grid;

    .form-control {
      min-height: 0 !important;
    }

    .selected {
      label {
        --bs-btn-color: none !important;
      }
    }

    label {
      padding: 0 !important;
      text-align: left !important;
      background-color: transparent !important;
      border: none !important;
      margin-top: 0 !important;

      &:hover {
        color: $color-black !important;
      }

      input {
        display: inline-block !important;
        appearance: auto !important;
        height: auto !important;
        width: auto !important;
        margin-right: 10px !important;
      }
    }
  }
}
