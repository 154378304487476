#service-selection {
  .btn-booking {
    min-width: 230px;
  }
}

.booking-widget {
  body {
    background-color: #FFF;
  }
}

#schedule-another-link {
  color: #2fbcb6;
}

.session-timer {
  background-color: rgba(197, 39, 45, 0.47);
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;

  .session-timer-value {
    font-weight: bolder;
  }
}

.session-timeout-info {
  background-color: rgba(39, 131, 197, 0.47);
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
}

.timezone-info {
  font-size: 80%;
  font-style: italic;
}

.font-italic {
  font-style: italic;
}

.hh-mb-2.timezone-info {
  font-weight: bold;
}

#booking-widget-progress-container {
  .progress {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .progress-bar  {
    background-color: #31b0d5;
  }

  hr {
    background-color: lightgrey;
    border: none;
    color: lightgrey;
    height: 1px;
  }
}

.payment-header {
  margin-top: 20px;

  .booking-widget-content {
    font-weight: normal;
  }
}

.no-service-icon {
  color: rgb(43, 172, 166);
  transform: scale(3.5);
}

.frc-container {
  display: flex;
  align-items: center;
  min-height: 52px;
}

.frc-content {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  margin: 4px 6px 0 0;
  overflow-x: auto;
  flex-grow: 1;
}

.frc-icon {
  fill: #222;
  stroke: #222;
  flex-shrink: 0;
  margin: 8px 8px 0;
}

.frc-button {
  cursor: pointer;
  padding: 2px 6px;
  background-color: #f1f1f1;
  border: 1px solid transparent;
  text-align: center;
  font-weight: 600;
  text-transform: none;
}

.frc-banner {
  position: absolute;
  bottom: 0;
  right: 6px;
  line-height: 1;

  a, a:focus {
    color: var(--color-text);
    text-decoration: none;
    outline: none;
  }

  a {
    display: inline-block;
  }
}

.frc-banner * {
  font-size: 10px;
  opacity: .8;
  text-decoration: none;
}

.frc-captcha-solution {
  display: none;
}
