// Common Components: Buttons
// -----------------------------------------------------

%btn-reset {
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;

  &:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
}

.btn {
  &[disabled],
  .disabled {
    cursor: default;
  }

  &-reset {
    @extend %btn-reset;
  }

  margin-bottom: 4px;
}
