// Webdoctor
// -----------------------------------------------------

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
}

p.title {
  font-weight: 300;
  font-size: 1.3em;
  line-height: 1.3;
  opacity: 0.75;
  margin-bottom: 60px;
  text-rendering: optimizeLegibility;
}

a {
  &.disabled {
    @extend %bootstrap-btn-disabled;
  }
}

#home p.title {
  margin-bottom: 15px;
}

#home {
  @media (min-width: 992px) {
    .doctors-and-treatments-row {
      position: relative;
    }

    .doctors-column {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.home-page {
  .unauthenticated-intro {
    margin: 30px 0;
  }

  .unauthenticated-list {
    li {
      list-style: none;
    }

    .btn {
      margin: 20px 0 0;
      @extend .text-center;
    }
  }
}

#how {
  padding-top: 15px;
}

p {
  margin-bottom: 10px;
  font-weight: 300;
}

.weathered {
  opacity: 0.6;
}

div.measurement-display {
  color: $background-color;
  padding-bottom: 20px;
  font-weight: 200;

  line-height: 1.8;
  opacity: 1;
  text-rendering: optimizeLegibility;
}

div.measurement-display-bp {
  color: $background-color;
  font-weight: 200;
  font-size: 1em;
  line-height: 1.8;
  opacity: 1;
  text-rendering: optimizeLegibility;
}

div.measurement-display-top-padding {
  padding-top: 20px;
}

div.measurement-display-margin-left {
  margin-left: 20px;
}

div.measurement-display-padding {
  padding-top: 20px;
}

span.height-slider,
span.weight-slider {
  margin-left: 20px;
}

span.weight-slider:before {
  content: '0st 0lb (0kg)';
  margin-right: 20px;
  opacity: 0.6;
}

span.weight-slider:after {
  content: '31st 7lb (200kg)';
  margin-left: 20px;
  opacity: 0.6;
}

span.bp-slider:before {
  content: '0 mmHg';
  margin-right: 20px;
  opacity: 0.6;
}

span.bp-slider:after {
  content: '300 mmHg';
  margin-left: 20px;
  opacity: 0.6;
}

div.slider-values {
  display: none;
}

input[type='range'] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 500px;

  background-color: silver;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 400px;
  height: 20px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 30px;
  width: 16px;
  //  border-radius: 50%;
  background: $background-color;
  margin-top: -4px;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
input[type='range']::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type='range']::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
}

/*hide the outline behind the border*/
input[type='range']:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}
input[type='range']::-ms-track {
  width: 300px;
  height: 16px;
  background: #ddd;
  border: none;
  border-radius: 10px;
  /*remove default tick marks*/
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  outline: none;
  background: #777;
  border-radius: 10px 0 0 10px;
}
input[type='range']::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
}
input[type='range']:focus::-ms-track {
  background: #ccc;
}
input[type='range']:focus::-ms-fill-lower {
  background: #888;
}

.lead {
  font-size: 20px;
  line-height: 28px;
  font-weight: 100;
  letter-spacing: 0.02em;
  margin-top: 40px;
  margin-bottom: 20px;
}

a,
a:hover,
a:focus {
  outline: none !important;
  text-decoration: none;
}

a {
  color: $c-pink;
  text-decoration: none;
}

a:hover,
a:focus {
  color: $c-pink;
  text-decoration: underline;
}

a:focus,
.btn:focus {
  outline: none;
}

.color-bg {
  a {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }
}

.img-center {
  margin: 0 auto;
}

.main-background {
  background: image_url('doctor-team-3.png') no-repeat;
  background-position: right 0px top;
  background-size: 545px 392px;
}

.bgWhite {
  background-color: white;
}

.bootstrap-filestyle {
  .input-group-btn {
    outline: 0;
  }
}

.padding-top-default-half {
  padding-top: calc($default-padding / 2);
}

.padding-top-default {
  padding-top: $default-padding;
}

.padding-top-bottom-default {
  padding-top: $default-padding;
  padding-bottom: $default-padding;
}

.padding-navigation {
  //padding-top: $navbar-height
}

.padding-login {
  padding: $default-padding 0;
}

.padding-register {
  padding: $default-padding 0;
}

.padding-messages {
  padding-top: $default-padding;
}

.padding-home-navbar {
  padding: $default-padding 0;
}

.padding-confirmation {
  padding-top: 100px;
  padding-bottom: 50px;
}

.padding-password {
  padding-top: 100px;
  padding-bottom: 50px;
}

.padding-top-bottom-treatment {
  padding: 40px 0;
}

.padding-top-bottom {
  padding: 40px 0;
}

.padding-bottom {
  padding-bottom: 20px;
}

.padding-pricing-top-bottom {
  padding: 125px 0;
}

.padding-top-navigation {
  padding-top: 20px;
}

.padding-top {
  padding: 50px 0 0;
}

.padding-main-text {
  padding: 50px 0 0 0;
}

.margin-left-default {
  margin-left: $default-padding;
}

.auto-width {
  width: auto;
}

.navbar {
  margin: 0;
  border-radius: 0;
  min-height: 70px;
}

.navbar-brand {
  height: 70px;
}

.section-cta {
  margin-bottom: 0px;
}

.section-title {
  position: relative;
  margin: 25px 0;
  color: $c-green;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
}

.section-description {
  margin: 30px 0;
  text-align: center;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.3;
  opacity: 0.75;
  text-rendering: optimizeLegibility;
}

.subsection-title {
  text-align: center;
  margin-top: 80px;

  @include sm-breakpoint {
    margin-top: 40px;
  }
}

.subsection-description {
  text-align: center;
  margin-bottom: 20px;
}

#couponNotFound {
  margin: 5px 0;
  padding: 5px;
}

.couponUnderline {
  text-decoration: underline;
}

.cta-video {
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

@media (max-width: $screen-sm-min) {
  .press-icons .row {
    margin-top: 15px;
  }

  .col-sm-15 {
    width: 20%;
    float: left;
  }

  .cta-video {
    bottom: 100px;
    top: 100px;
  }
}

@include breakpoint(min-width, $break-mobile + 1) {
  .white-bg {
    background: #fff;
  }

  .gray-bg {
    background: #f3f3f3;
  }

  .color-bg {
    background: $c-pink;
  }

  .dark-bg {
    background: $background-dark-color;
  }
}

.light-typo {
  color: #fff;
}

.color-bg.light-typo .section-title:after {
  background: #fff;
}

#female-treatments-section,
#male-treatments-section,
#home-testing-section {
  display: block;
  position: relative;
  top: -62px;
  visibility: hidden;
}

@media (min-width: $screen-sm-min) {
  #female-treatments-section,
  #male-treatments-section {
    top: -70px;
  }

  #home-testing-section {
    top: -30px;
  }
}

#main-nav .navbar-toggle {
  margin-right: 0;
  border: none;
}

#main-nav .icon-bar {
  background: #4e5961;
}

.navbar-brand,
.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:focus {
  position: relative;
  padding: 0 10px;
  font-size: 28px;
  line-height: 70px;
  margin-top: -3px;
  font-weight: 600;
  color: #777;
  font-variant: small-caps;
  letter-spacing: 0.02em;
  -webkit-transition: font-size 0.2s ease-out;
  transition: font-size 0.2s ease-out;
  overflow: hidden;
}

#main-nav .navbar-nav {
  margin-bottom: 0;
}

#main-nav.scrolled .navbar-toggle {
  color: #5aa9ce;
  border-color: #5aa9ce;
}

#main-nav.scrolled .navbar-toggle .icon-bar {
  background: #5aa9ce;
}

#main-nav .container {
  padding: 0px 20px;
  -webkit-transition: padding 0.3s ease-out;
  transition: padding 0.3s ease-out;
}

#main-nav .nav a {
  position: relative;
  z-index: 1;
}

#main-nav .nav a,
#main-nav .nav a:active,
#main-nav .nav a:hover {
  padding: 1px 5px;
  font-size: 14px;
  line-height: 70px;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: transparent;
  color: #4e5961;
  opacity: 0.75;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

#main-nav .nav .active a,
#main-nav .nav a:hover {
  opacity: 1;
}

#main-nav .nav a:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 10%;
  content: '';
  width: 80%;
  height: 3px;
  background: $c-pink;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#main-nav .nav a:hover:before,
#main-nav .nav .active a:before {
  bottom: 7px;
  opacity: 1;
}

.no-csstransforms #main-nav .nav a:before {
  display: none;
}

.no-csstransforms #main-nav .nav a:hover,
.no-csstransforms #main-nav .nav .active a {
  color: #5aa9ce;
}

#main-nav .open .dropdown-toggle .caret {
  border-top: 0;
  border-bottom: 4px dashed;
}

#main-nav .dropdown-menu a {
  font-size: 14px;

  &.female-submenu-link {
    color: #ff84c5;
  }

  &.female-submenu-link:hover,
  &.female-submenu-link:focus {
    color: darken(#ff84c5, 2%);
  }

  &.male-submenu-link {
    color: $c-blue;
  }

  &.male-submenu-link:hover,
  &.male-submenu-link:focus {
    color: darken($c-blue, 2%);
  }
}

#treatments-dropdown-menu {
  width: 630px;
  right: -180px;

  @include breakpoint(max-width, $screen-md-min) {
    right: -400px;
  }

  ul {
    padding-left: 0px;
  }

  li {
    display: block;
    float: none;
  }

  li > a:hover,
  li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: whitesmoke;
  }

  li > a {
    display: inline-block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: nowrap;
  }
}

.page-header {
  @include sm-breakpoint {
    margin: 12px 0;
    padding-bottom: 0;
    border-bottom: none;

    .section-title {
      margin-bottom: 0;
      float: none !important;
      text-align: center;
    }
  }
}

.section-title {
  &.back-link {
    display: block;
    color: $text-color;
  }

  small {
    font-size: 65%;
  }
}

.site-logo {
  max-height: 54px;
}

.logo-small {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.to-top {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  left: 10px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #eee;
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-brand:hover .logo-small {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-brand:hover .to-top {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.navbar-no-menu {
  min-height: $navbar-height;
}

.buttonMain {
  position: absolute;
  bottom: 0;
  left: 0;
}

.cta {
  padding: 50px 0 30px;
}

#pricing,
.cta {
  .btn-qubico {
    @include sm-breakpoint {
      display: inline-block;
      min-width: auto;
    }
  }
}

.cta-strong {
  font-weight: 500;
}

.cta .cta-message p {
  padding: 15px 0 20px;
  font-size: 22px;
  line-height: 32px;
}

.cta .cta-message strong {
  font-size: 24px;
  letter-spacing: 0.02em;
}

.cta .cta-button {
  padding: 0;
}

#pricing {
  @include sm-breakpoint {
    text-align: center;
  }
}

.features .section-description {
  margin-bottom: 80px;
}

.features .icon {
  width: 80px;
  height: 80px;
  text-align: center;
  background: #cccfd5;
  color: #fff;
  font-size: 28px;
  line-height: 80px;
  border-radius: 50%;
  box-shadow: 0 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.features .media:hover .icon {
  background: $c-pink;
}

.features .icon .icon-1 {
  width: 80px;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-out 0.2s;
  transition: all 0.3s ease-out 0.2s;
}

.features .icon .icon-2 {
  width: 80px;
  position: relative;
  top: -80px;
  opacity: 0;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .media:hover .icon .icon-1 {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .media:hover .icon .icon-2 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-out 0.2s;
  transition: all 0.3s ease-out 0.2s;
}

.features .media {
  margin-bottom: 40px;
}

.features .media-body p {
  font-size: 16px;
}

.features .media h4 {
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.features .media:hover h4 {
  color: $c-pink;
}

.process-list {
  margin: 0;
  padding: 0;
  background: transparent;
  list-style: none;
  text-align: center;
  width: 100%;
}

.process-list li {
  display: inline-block;
  background: transparent;
  margin: 20px 0;
  width: 24%;
  vertical-align: top;
}

.process-list li h5 {
  text-transform: uppercase;
  /*TODO: fix for larger screens*/
  min-height: 2em;
}

.process-list .icon {
  position: relative;
  display: block;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
  background: #bbb;
  border-radius: 50%;
  font-size: 48px;
  line-height: 120px;
  color: #fff;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.process-list .icon:hover {
  font-size: 36px;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.process-list li:nth-child(1) .icon {
  background: #eee;
}

.process-list li:nth-child(2) .icon {
  background: #ddd;
}

.process-list li:nth-child(3) .icon {
  background: #ccc;
}

.process-list li:nth-child(4) .icon {
  background: #bbb;
}

.process-list li:nth-child(5) .icon {
  background: #aaa;
}

.process-list li.colored .icon {
  background: $c-pink;
}

.process-item-description {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 10px;
}

.scrollimation .process-list li:nth-child(1) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4);
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4);
}

.touch .scrollimation .process-list li:nth-child(1),
.scrollimation.in .process-list li:nth-child(1) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(2) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.4s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.4s;
}

.touch .scrollimation .process-list li:nth-child(2),
.scrollimation.in .process-list li:nth-child(2) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(3) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.8s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 0.8s;
}

.touch .scrollimation .process-list li:nth-child(3),
.scrollimation.in .process-list li:nth-child(3) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(4) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.2s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.2s;
}

.touch .scrollimation .process-list li:nth-child(4),
.scrollimation.in .process-list li:nth-child(4) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.scrollimation .process-list li:nth-child(5) {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.6s;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.42, 1.4) 1.6s;
}

.touch .scrollimation .process-list li:nth-child(5),
.scrollimation.in .process-list li:nth-child(5) {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.process-list h4 {
  font-size: 21px;
  font-weight: 400;
}

.process-box {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.process-line {
  position: absolute;
  width: 80%;
  margin: 0 22%;
  height: 0;
  top: 80px;
  border-top: 3px dotted $c-pink;
  z-index: -1;
}

.scrollimation .process-line {
  width: 0;
  -webkit-transition: width 2.5s ease-out;
  transition: width 2.5s ease-out;
}

.touch .scrollimation .process-line,
.scrollimation.in .process-line {
  width: 50%;
}

.team-member {
  margin-bottom: 30px;
}

.member-thumb {
  text-align: center;
}

.member-thumb h4 {
  font-size: 21px;
  margin: 10px 0;
}

.member-thumb .title {
  font-size: 18px;
  margin: 10px 0;
  color: $c-pink;
}

.team-member .member-details,
.team-member .member-details-no-hover {
  position: relative;
  padding: 30px;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-out;

  @include sm-breakpoint {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}

.team-member:hover .member-details {
  background: $c-pink;
  color: #fff;
}

.team-member.right .member-details,
.team-member.right .member-details-no-hover {
  box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.08);

  @include sm-breakpoint {
    box-shadow: none;
  }
}

@media (min-width: $screen-sm-min) {
  .team-member .member-details,
  .team-member .member-details-no-hover {
    margin: 20px 0 0 20px;
    padding-left: 80px;
  }

  .team-member .member-details:after,
  .team-member .member-details-no-hover:after {
    display: block;
    content: '';
    position: absolute;
    left: -18px;
    top: 30px;
    width: 0px;
    height: 0px;
    background: transparent;
    border: 20px solid transparent;
    border-left: 20px solid #f5f5f5;
    box-shadow: -3px 0 0 rgba(0, 0, 0, 0.08);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border-left-color 0.3s ease-out;
    transition: border-left-color 0.3s ease-out;
  }

  .team-member.right .member-details:after,
  .team-member.right .member-details-no-hover:after {
    position: absolute;
    left: auto;
    right: -17px;
    border-left: 20px solid transparent;
    border-right: 20px solid #f5f5f5;
    box-shadow: 3px 0 0 0 rgba(0, 0, 0, 0.08);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: border-right-color 0.3s ease-out;
    transition: border-right-color 0.3s ease-out;
  }
}

.team-member:hover .member-details:after {
  border-left-color: $c-pink;
}

.team-member.right:hover .member-details:after {
  border-left-color: transparent;
  border-right-color: $c-pink;
}

.team-member .member-details:before {
  display: block;
  content: '\201D';
  position: absolute;
  left: 20px;
  top: 50px;
  font-size: 100px;
  font-weight: 800;
  line-height: 60px;
  font-family: Arial;
  color: $c-pink;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.team-member:hover .member-details:before {
  color: #fff;
}

.team-member .member-socials {
  list-style: none;
  margin: 0;
  padding: 0;
}

.team-member .member-socials li {
  display: inline;
  text-align: center;
  margin: 0 2px;
}

.team-member .member-socials li a {
  display: inline-block;
  padding: 0;
  min-width: 40px;
  height: 40px;
  font-size: 21px;
  line-height: 40px;
  color: $c-pink;
  border-radius: 5px;
  box-shadow: 0 3px 0 0 transparent;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.team-member:hover .member-socials li a {
  color: #fff;
}

.team-member .member-socials li a:hover {
  background: #fff;
  color: $c-pink;
  box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
}

.team-member .member-details p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

// TODO: Refactor female/male/service buttons to a generic class and remove duplicates
.female-treatment .item {
  position: relative;
  top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #777;
  border-radius: 7px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  transition: 0.06s ease-out;
}

.female-treatment .item.featured {
  top: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

.female-treatment .item:hover,
.female-treatment .item.featured {
  @include breakpoint(min-width, $break-mobile) {
    color: #fff;
    background: $c-pink;
  }
}

.female-treatment .item h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.female-treatment .item.featured h2 {
  margin-bottom: 10px;
}

.female-treatment .item p {
  margin-bottom: 5px;
  font-weight: 400;
}

.female-treatment .item .icon {
  display: inline-block;
  margin-bottom: 30px;
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  transition: 0.06s ease-out;
}

.female-treatment .item.featured .icon,
.female-treatment .item:hover .icon {
  @include breakpoint(min-width, $break-mobile) {
    color: #ff84c5;
    background: #fff;
  }
}

.female-treatment .item .btn-female {
  margin-top: 15px;
  margin-bottom: 0;
}

.female-treatment .item.featured .btn-female,
.female-treatment .item:hover .btn-female {
  @include breakpoint(min-width, $break-mobile) {
    color: darken($c-pink, 4%);
    background: #fff;

    &:hover {
      top: 1px;
    }
  }
}

.female-treatment .item .price {
  font-size: 48px;
  font-weight: 100;
  line-height: 48px;
  margin-bottom: 30px;
}

.female-treatment .item.featured .price {
  margin-bottom: 40px;
}

.male-treatment .item {
  position: relative;
  top: 10px;
  background: rgba(255, 255, 255, 1);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 7px;
  color: #777;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  transition: 0.06s ease-out;
}

.male-treatment .item.featured {
  top: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

.male-treatment .item:hover,
.male-treatment .item.featured {
  @include breakpoint(min-width, $break-mobile) {
    color: #fff;
    background: $c-blue;
  }
}

.male-treatment .item h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.male-treatment .item.featured h2 {
  margin-bottom: 10px;
}

.male-treatment .item p {
  margin-bottom: 5px;
  font-weight: 400;
}

.male-treatment .item .icon {
  display: inline-block;
  margin-bottom: 30px;
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  transition: 0.06s ease-out;
}

.male-treatment .item.featured .icon,
.male-treatment .item:hover .icon {
  @include breakpoint(min-width, $break-mobile) {
    color: #3677b3;
    background: #fff;
  }
}

.male-treatment .item .btn-male {
  margin-top: 15px;
  margin-bottom: 0;
}

.male-treatment .item.featured .btn-male,
.male-treatment .item:hover .btn-male,
.services-menu .item.featured .btn-home,
.services-menu .item:hover .btn-home {
  @include breakpoint(min-width, $break-mobile) {
    color: darken($c-blue, 10%);
    background: #fff;

    &:hover {
      top: 1px;
    }
  }
}

.male-treatment .item .price {
  font-size: 48px;
  font-weight: 100;
  line-height: 48px;
  margin-bottom: 30px;
}

.male-treatment .item.featured .price {
  margin-bottom: 40px;
}

.services-menu {
  margin-top: 30px;
}

.services-menu .item .col-sm-6 {
  padding: 0;
}

.services-menu .item {
  position: relative;
  top: 10px;
  background: rgba(255, 255, 255, 1);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 7px;
  color: #777;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  transition: 0.06s ease-out;
}

.services-menu .item.featured {
  top: 0;
  padding-top: 50px;
  padding-bottom: 50px;
}

.services-menu .item:hover,
.services-menu .item.featured {
  @include breakpoint(min-width, $break-mobile) {
    color: #fff;
    background: $btn-gray-color;
  }
}

.services-menu .item h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.services-menu .item.featured h2 {
  margin-bottom: 10px;
}

.services-menu .item p {
  margin-bottom: 5px;
  font-weight: 400;
}

.services-menu .item .icon {
  display: inline-block;
  margin-bottom: 30px;
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  transition: 0.06s ease-out;
}

.services-menu .item.featured .icon,
.services-menu .item:hover .icon {
  @include breakpoint(min-width, $break-mobile) {
    color: $btn-gray-color;
    background: #fff;
  }
}

.services-menu .item .btn-home {
  margin-top: 15px;
  margin-bottom: 0;
}

.services-menu .item.featured .btn-home,
.services-menu .item:hover .btn-home {
  @include breakpoint(min-width, $break-mobile) {
    background: #fff;
    color: $btn-gray-color;

    &:hover {
      top: 1px;
    }
  }
}

.services-menu .item .price {
  font-size: 48px;
  font-weight: 100;
  line-height: 48px;
  margin-bottom: 30px;
}

.services-menu .item.featured .price {
  margin-bottom: 40px;
}

.services-menu p.icon i {
  vertical-align: middle;
}

.female-treatment,
.male-treatment,
.services-menu {
  @include sm-breakpoint {
    padding: 0;
  }

  .item {
    @include breakpoint(max-width, $break-mobile - 1) {
      box-shadow: none;
      background: transparent;
      border-radius: 0;
    }

    .btn-female,
    .btn-male,
    .btn-home {
      width: 100%;

      > span:not(.continue) {
        &:first-child {
          width: 84%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      @include breakpoint(min-width, $break-mobile) {
        max-width: 90%;
      }

      @include touch-devices {
        //padding-top: 16px;
        //padding-bottom: 16px;
      }
    }

    .icon {
      @include sm-breakpoint {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 49px;
      }
    }
  }
}

.contact-item h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-item .icon {
  display: block;
  font-size: 48px;
  color: $c-pink;
  text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.contact-item .icon:hover {
  color: $c-pink;
  transform: scale(1.3) translateY(-10px);
}

.bl_form {
  margin: 30px 0 0;
}

.bl_form input {
  padding-top: 15px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 0px rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.lb_wrap .lb_label.top,
.lb_wrap .lb_label.bottom {
  left: 66px !important;
}

.lb_wrap .lb_label.left {
  left: 0;
}

.lb_label {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #ccc;
}

.no-placeholder .lb_label {
  display: none;
}

.lb_label.active {
  color: #aaa;
}

.custom-form,
#contact-form {
  .alert {
    @include sm-breakpoint {
      margin-top: 0;
    }
  }

  .form-group {
    .alert {
      @include sm-breakpoint {
        margin: 6px 0 0;
      }
    }

    label {
      display: none;
      font-size: 18px;
      line-height: 24px;
      font-weight: 100;
      text-transform: uppercase;
    }

    .radio-label-inline,
    .radio-inline {
      display: inline-block;
      margin: 0;
      padding: 0;
    }

    .radio-alert {
      margin-top: 30px;
    }

    .radio-input {
      margin-right: 4px;
    }

    .radio-alert-margin {
      margin-top: 60px;
    }

    .radio-inline {
      height: 20px;
    }
  }

  &.no-placeholder .form-group label {
    display: block;
  }

  .controls {
    padding: 0;
    margin-top: 40px;
  }

  &.no-placeholder .controls {
    margin-top: 0;
  }

  .form-control {
    display: inline;
    background: #fff;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
    height: 52px;
    font-size: 18px;
    line-height: 32px;
    color: #aaa;
    font-weight: 400;
    padding-left: 64px;
    vertical-align: top;
  }

  .form-group.half-width {
    width: 40%;
    float: left;
  }
}

.custom-form {
  margin-top: 10px;
  margin-bottom: 30px;

  .form-group {
    label {
      display: block;
      cursor: pointer;
      text-transform: none;
    }

    .form-inline-control {
      display: inline;
      vertical-align: top;
    }
  }
}

#bmi {
  margin-left: 20px;
  padding-top: 50px;
}

.custom-form .form-group [class*='fa'],
#contact-form .form-group [class*='fa'] {
  display: block;
  width: 64px;
  position: absolute;
  top: 14px;
  left: 5px;
  color: #eee;
  font-size: 24px;
  line-height: 52px;
  text-align: center;
  font-weight: 300;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.custom-form .form-group [class*='fa'].active,
#contact-form .form-group [class*='fa'].active {
  color: #ccc;
}

#contact-form.no-placeholder .form-group [class*='fa'] {
  top: 30px;
}

#contact-form textarea.form-control {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 14px;
  vertical-align: top;
}

.custom-form .form-control:focus,
#contact-form .form-control:focus {
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

#contact-form .error-message {
  padding: 5px 0;
  position: absolute;
  top: -35px;
  right: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #ff3345;
  z-index: 10;
}

#contact-form.no-placeholder .error-message {
  top: 0;
}

.text-copy {
  font-size: 12px;
}

.text a {
  text-decoration: none;
}

.social-links {
  list-style: none;
  margin: calc($default-padding / 2) 0;
  padding: 0;
  text-align: center;
}

.social-links li {
  display: inline-block;
  margin: 0 $default-padding;
  border-radius: 3px;
  box-shadow: 0 4px 0 transparent;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: 0 $default-padding;
}

.social-links li a {
  display: block;
  line-height: 20px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 48px;
}

.social-links .fa {
  transition: color 0.3s ease;
}

.social-links li .fa:hover {
  color: $text-color;
}

.scrollimation.fade-in {
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.scrollimation.fade-in.in {
  opacity: 1;
}

.scrollimation.scale-in {
  opacity: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.17, 0.89, 0.67, 1.57), opacity 0.6s ease-out;
  transition: transform 0.6s cubic-bezier(0.17, 0.89, 0.67, 1.57), opacity 0.6s ease-out;
}

.scrollimation.scale-in.in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.scrollimation.fade-right {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

.scrollimation.fade-right.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.scrollimation.fade-left {
  opacity: 0;
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

.scrollimation.fade-left.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.scrollimation.fade-up {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: -webkit-transform 0.4s ease-out, opacity 0.4s ease-in;
  transition: transform 0.4s ease-out, opacity 0.4s ease-in-out;
}

.scrollimation.fade-up.in {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.scrollimation.d1 {
  transition-delay: 0.2s;
}

.scrollimation.d2 {
  transition-delay: 0.4s;
}

.scrollimation.d3 {
  transition-delay: 0.6s;
}

.scrollimation.d4 {
  transition-delay: 0.8s;
}

.touch .scrollimation.fade-in {
  opacity: 1;
}

.touch .scrollimation.scale-in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.touch .scrollimation.fade-right {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touch .scrollimation.fade-left {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touch .scrollimation.fade-up {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

a,
a:hover,
a:focus,
.services .item:hover h3,
.features .media:hover h4,
.member-thumb .title,
.team-member .member-details:before,
.team-member .member-socials li a,
.team-member .member-socials li a:hover,
.project-thumb .project-title:hover,
.contact-item .icon,
.contact-item .icon:hover,
.social-links li:hover a {
  color: $c-green;
}

.section-title:after,
.color-bg,
#main-nav .nav a:before,
.jumbotron,
.features .media:hover .icon,
.services .icon,
.process-list li.colored .icon,
.ipad-slider .flex-direction-nav .flex-prev,
.ipad-slider .flex-direction-nav .flex-next,
.team-member:hover .member-details,
.chart-wrapper,
#filter-works ul li a:hover,
#filter-works ul li.active a,
.project-thumb .overlay-mask,
.imac-slider .flex-direction-nav .flex-prev,
.imac-slider .flex-direction-nav .flex-next,
.pricing .item:hover,
.pricing .item.featured {
  background: $c-green;
}

.process-line {
  border-top-color: $c-green;
}

.team-member:hover .member-details:after {
  border-left-color: $c-green;
}

.team-member.right:hover .member-details:after {
  border-left-color: transparent;
  border-right-color: $c-green;
}

.title-text {
  font-size: 2.5em;
  font-weight: 600;
  line-height: 1.1;
  color: $c-green;
}

.offer-text {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.1;
  color: $c-green;
}

#contact-form .form-group {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Treatment Form */
.form-question,
.form-question-style-only {
  font-weight: 200;
  font-size: 1.2em;
  line-height: 1.8;
  opacity: 1;
  text-rendering: optimizeLegibility;
}

label.form-question.medical-question {
  margin-bottom: 30px;
}

.form-question-radio {
  display: none;
}

.question-label-text {
  font-size: 18px;
}

label.form-question {
  display: block;
}

span.radio,
span.checkbox {
  display: inline-block;
}

.form-question-secondary {
  margin-left: 10%;
  padding-top: 0px;
}

.form-button label,
.form-ok-button label,
.form-video-button label,
.form-button-style-only label {
  @extend %btn-qubico;
}

.form-ok-button,
.textarea-chars-remaining {
  padding-left: 20px;
}

.form-ok-button {
  width: 100%;
  background-color: #ffffff;

  @include sm-breakpoint {
    width: auto;
  }
}

@media (max-width: $screen-sm-min) {
  div.form-ok-button.floating {
    border-top: 1px solid $background-color;
    left: 15px;
  }
}

.form-question-section {
  background-color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

  .member-thumb {
    margin-left: 16px;
  }

  @include sm-breakpoint {
    box-shadow: none;
    border-radius: 0;
  }

  @include xs-breakpoint {
    p + h3 {
      margin-top: 60px;
    }

    .member-details-no-hover {
      p:first-child + h3 {
        margin-top: 0;
      }
    }
  }
}

#scrollUp,
#scrollDown {
  border-color: #ffffff;
  font-family: inherit;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  background: #38e1da;
  color: #fff;
  transition: none;
  min-width: 150px;
}

#scrollUp:hover,
#scrollDown:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}

#scrollUpLabel,
#scrollDownLabel {
  margin-right: 10px;
}

#question-footer {
  background: #fff;
  color: #4e5961;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  margin-top: 50px;
  z-index: 999;
}

#question-status {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.3;
  opacity: 1;
  text-rendering: optimizeLegibility;
}

.onoffswitch {
  position: relative;
  width: 181px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999999;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: 'Meters/ Kilgorams';
  padding-left: 10px;
  background-color: #eeeeee;
  color: #999999;
}

.onoffswitch-inner:after {
  content: 'Feet/ Stones';
  padding-right: 10px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 147px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.tooltip-inner {
  width: 300px;
}

.bp-inputs {
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-breadcrumb-section {
  background-color: white;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

  @include sm-breakpoint {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}

.long-form-step-count {
  font-weight: bold;
  text-transform: uppercase;
  color: $background-color;
  padding-bottom: 15px;
}
.wd-wizard-progress-bar {
  padding: 0;
}
.wd-wizard-progress-bar .progress {
  height: 10px;
  border-radius: 5px;
}
.wd-wizard-progress-bar .progress-bar {
  height: 10px;
  background-color: $background-color;
}

.shipping-info-details {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}

.patient-info p {
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 20px;
}

.patient-info h4 {
  font-size: 21px;
  margin-top: 40px;
}
.charity-padding {
  margin-top: 20px;
}

p.blog {
  text-align: justify;
}

p.blog-start:first-letter {
  font-size: 150%;
  text-transform: uppercase;
}

p.blog-date {
  font-size: 12px;
}

p.blog-badge {
  padding-top: 30px;
}

h1.blog {
  color: $background-color;
  font-size: 2em;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
}

h3.blog {
  padding-top: 40px;
}

blockquote.blog {
  border-left: solid $background-color;
  text-align: justify;
}

.medical-input.text {
  padding-bottom: 0px;
}

@media (max-width: 767px) {
  .team-member:hover .member-details:after,
  .team-member.right:hover .member-details:after {
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $c-green;
  }
}

@media (max-width: $screen-lg-min) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

@media (max-width: $screen-md-min) {
  .col-md-15 {
    width: 20%;
    float: left;
  }

  .text {
    font-size: 13px;
  }

  .text-copy {
    font-size: 10px;
  }

  .padding-navigation {
    padding-top: 52px;
  }
}

@media (max-width: $screen-sm-min) {
  h1,
  .skills h2 {
    font-size: 24px;
    line-height: 28px;
  }

  h2 {
    font-size: 21px;
    line-height: 24px;
  }

  .section-title {
    font-size: 28px;
    line-height: 36px;
  }

  .cta .cta-message p,
  .cta .cta-message strong {
    font-size: 18px;
    line-height: 24px;
  }

  #main-nav .nav a {
    text-align: center;
    border-bottom: 1px solid #eaeaea;
  }

  #main-nav .nav a:before {
    display: none;
  }

  .cta .cta-message {
    text-align: center;
  }

  .services .item {
    margin-bottom: 60px;
  }

  .process-list li {
    display: block;
    width: 100%;
    text-align: center;
  }

  .process-line {
    display: none;
  }

  .team-member .member-thumb {
    margin-bottom: 30px;
    margin-left: 0;

    .cta-video {
      width: 86%;
      display: inline-block;
    }
  }

  .team-member {
    margin-bottom: 60px;
  }

  .team-member .member-thumb img {
    width: 300px;
    max-width: 100%;
  }

  .team-member .member-details:after,
  .team-member.right .member-details:after {
    left: 50%;
    margin-left: -20px;
    top: -40px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fafafa;
    box-shadow: none;
    transform: rotate(0deg);
    transition: border-bottom-color 0.3s ease-out;

    @include sm-breakpoint {
      display: none;
    }
  }

  .team-member:hover .member-details:after,
  .team-member.right:hover .member-details:after {
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $c-pink;
  }

  .team-member .member-details,
  .team-member.right .member-details {
    margin: 20px 0 0 0;
  }

  .team-member-description {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .skills h2 {
    margin-bottom: 60px;
  }

  .project-item .project-thumb {
    max-width: 400px;
    margin: 0 auto;
  }

  .pricing .item {
    top: 0;
    margin: 0 auto 40px;
    max-width: 400px;
  }

  .female-treatment {
    margin-bottom: 20px;
  }

  .female-treatment .item .icon,
  .male-treatment .item .icon,
  .services-menu .item .icon {
    margin-bottom: 5px;
    width: 25px;
    height: 25px;
    font-size: 20px;
    line-height: 25px;
  }

  #getstarted h3 {
    margin-bottom: 0px;
    font-size: 20px;
  }

  .social-links li,
  .charity-links li {
    margin: 0 $default-padding;
  }

  .social-links li a,
  .charity-links li a {
    font-size: 36px;
  }

  #question-footer {
    display: none;
  }

  .question-label-text > label {
    font-weight: 200;
    font-size: 1.4em;
    line-height: 1.8;
  }
}

.watch-final-words-small {
  display: none;
}

@media only screen and (max-width: $screen-xs-max) {
  .nav-breadcrumb > .nav-breadcrumb-step .nav-breadcrumb-stepnum {
    color: $text-color;
    font-size: 11px;
    margin-bottom: 5px;
  }

  div.slider-values {
    display: block;
    padding-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
    opacity: 0.6;
  }

  div.bp-slider-values {
    margin-left: 0px;
  }

  span.height-slider:before,
  span.height-slider:after,
  span.weight-slider:before,
  span.weight-slider:after,
  span.bp-slider:before,
  span.bp-slider:after {
    content: '';
    margin-right: 0px;
    margin-left: 0px;
    opacity: 1;
  }

  input[type='range'] {
    width: 260px;
    height: 20px;
  }

  blockquote.blog {
    font-size: 13px;
  }

  .padding-top {
    padding: 75px 0 0;
  }

  .padding-pricing-top-bottom {
    padding: 40px 0 50px 0;
  }

  p.title {
    margin-bottom: 0px;
  }

  /* main image */
  .main-background {
    background-size: 320px;
  }

  /*monologos */
  section.monologos img {
    width: 70px;
  }

  /* pricing */
  section#pricing img {
    width: 75%;
  }

  /* team member */
  .team-member .member-details p {
    font-size: 11px;
    line-height: 16px;
  }

  .team-member .member-details:before {
    content: '';
  }

  /* footer style overrides */
  .social-links li a {
    font-size: 36px;
  }

  .text {
    font-size: 1em;
  }

  .text-copy {
    font-size: 8px;
  }

  .question-label-text > label {
    font-weight: 200;
    font-size: 1.2em;
    line-height: 1.8;
  }

  .form-question-section {
    margin-bottom: 40px;
  }

  .padding-navigation {
    padding-top: 52px;
  }

  #question-footer {
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  #scrollUp,
  #scrollDown {
    min-width: 32px;
  }

  #scrollUpLabel,
  #scrollDownLabel {
    display: none;
  }

  span.radio,
  span.checkbox {
    padding-left: 0px;
  }

  .watch-final-words-large {
    display: none;
  }

  .watch-final-words-small {
    display: inline;
  }

  #question-footer {
    display: none;
  }

  #contact-form .form-control {
    top: 13px;
    height: 40px;
    font-size: 14px;
    line-height: 25px;
  }

  #contact-form .form-group [class*='fa'] {
    font-size: 14px;
    line-height: 40px;
  }

  #contact-form textarea.form-control {
    font-size: 14px;
  }

  .padding-top-bottom {
    padding: 5px 0;
  }

  .nav-breadcrumb-section {
    margin-top: 10px;
  }

  .section-title {
    font-size: 1.5em;
  }

  .section-description {
    margin-top: 16px;
  }

  h3.shipping-header {
    font-size: 1em;
  }

  p.shipping-info {
    font-size: 0.6em;
    padding: 10px 0 0 0;
    line-height: 10px;
  }

  ul.shipping-info {
    font-size: 0.6em;
    padding: 0 0 0 20px;
    line-height: 10px;
  }

  #coupon-input {
    padding-bottom: 10px;
  }

  h4.payment-info {
    font-size: 11px;
  }

  p.payment-info {
    font-size: 0.6em;
    padding: 10px 0 0 0;
    line-height: 10px;
  }
}

/* Messages */

.messages-container {
  padding-top: 20px;
}

/* Nav Pills */

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: $c-green;
}

.nav-pills ul.submenu {
  list-style: none;
  margin-top: 16px;
}

/* Font Awesome */

a.fa {
  color: $text-color;
  text-decoration: none;
}

a.fa:hover {
  color: $text-highlight-color;
}

/* Common */
.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.anchor-offset-navbar {
  display: block;
  position: relative;
  visibility: hidden;
}

.flash-messages {
  + .alert {
    margin-top: 0;
  }

  @include sm-breakpoint {
    margin: 0 15px;
  }
}

.custom-messages {
  @include sm-breakpoint {
    margin: 0 15px;
  }
}

.alert {
  margin-top: 20px;
}

.alert-danger .form-ok-button {
  background-color: #f2dede;
}

.form-ok-button.floating {
  background-color: #ffffff;
}

.full-width {
  width: 100%;
}

.form-error {
  margin-top: $default-margin;
}

.borderless tbody tr td,
.borderless tbody tr th,
.borderless thead tr th {
  border: none;
}

.danger-link,
.danger-link:focus {
  color: $btn-danger-color;
}

.inline-block {
  display: inline-block;
}

/* Contact Form */

.contact_form_duplicate_to_myself label#contact_form_duplicate_to_myself_label {
  display: inline;
  text-transform: none;
  opacity: 1;
}

.contact_form_duplicate_to_myself input#contact_form_duplicate_to_myself {
  margin-right: $default-margin;
}

/* Shipping Addres Form */

#shipping-address .form-control {
  display: inline;
  background: #fff;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  height: 52px;
  font-size: 18px;
  line-height: 32px;
  color: #aaa;
  font-weight: 400;
  vertical-align: top;
}

#shipping-address .form-control:focus {
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

#shipping-address .input-group-addon {
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

.ui-datepicker {
  z-index: 2 !important; // ensure datepicker appears 'above' other form elements.
}

/* Payment */

.payment-section .shipping-address {
}

.payment-section #paymentButton,
.payment-section #showTreatmentLink {
  background-color: $btn-primary-color;
}

.payment-container {
  margin-bottom: 60px;
}

.payment-container .form-question-section {
  margin-bottom: $default-padding;
}

.payment-container .digicert-text {
  text-align: center;
}

.payment-container .edit-link {
  margin-left: calc($default-padding / 2);
  opacity: 0.7;
}

.payment-container .toc {
  font-size: 0.8em;
}

@media (max-width: $screen-md-min) {
  #main-nav {
    .open .submenu {
      box-shadow: inset 0 1px 0px #c5c5c5;
    }

    .submenu {
      padding-bottom: 0;
      background: #f1f1f1;

      a {
        border-bottom: 1px solid #dcdcdc;
      }
    }

    .navbar-collapse {
      max-height: 400px;
    }
  }

  .team-member .member-details:before {
    content: '';
  }
}

.treatment-page {
  @include sm-breakpoint {
    .section-title {
      margin: 0 0 20px;
    }
  }
}

/* Bootstrap CSS - Columns Vertical Centering */

/* columns of same height styles */

.container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
}
.row-xs-height {
  display: table-row;
}
.col-xs-height {
  display: table-cell;
  float: none;
}
@media (min-width: 768px) {
  .container-sm-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
  }
  .row-sm-height {
    display: table-row;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
  }
}
@media (min-width: 992px) {
  .container-md-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
  }
  .row-md-height {
    display: table-row;
  }
  .col-md-height {
    display: table-cell;
    float: none;
  }
}
@media (min-width: 1200px) {
  .container-lg-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
  }
  .row-lg-height {
    display: table-row;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
  }
}

/* vertical alignment styles */
.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}

/* fix firefox responsive images */
.img-responsive {
  width: 100%;
}

/* Digicert */

.digicert {
  padding-bottom: calc($default-padding / 2);
}

.digicert-text {
  font-size: 14px;
}

/* Questionnare */

$questionnare-yellow-colour: orange;
$questionnare-red-colour: red;

#questionnare-form .yellow {
  color: $questionnare-yellow-colour;
}

#questionnare-form .red {
  color: $questionnare-red-colour;
}

#questionnare-form .fa {
  margin-right: $default-padding;
}

#questionnare-form .yellow {
  border-left: 5px solid $questionnare-yellow-colour;
  padding-left: $default-padding;
}

#questionnare-form .yellow label,
#questionnare-form .red label {
  opacity: 1;
}

#questionnare-form .red {
  border-left: 5px solid $questionnare-red-colour;
  padding-left: $default-padding;
}

#questionnare-form label {
  opacity: 0.5;
}

#questionnare-form .sub-question {
  margin-left: 2 * $default-padding;
  font-size: 16px;
}

#questionnare-menu-option .badge.yellow.active {
  margin-top: 2px;
  color: $questionnare-yellow-colour;
  background-color: white;
}

#questionnare-menu-option .badge.yellow.inactive {
  margin-top: 2px;
  background-color: $questionnare-yellow-colour;
}

#questionnare-menu-option .badge.red.active {
  margin-top: 2px;
  color: $questionnare-yellow-colour;
}

#questionnare-menu-option .badge.red.active {
  margin-top: 2px;
  margin-right: 5px;
  color: $questionnare-red-colour;
}

#questionnare-menu-option .badge.red.inactive {
  margin-top: 2px;
  margin-right: 5px;
  background-color: $questionnare-red-colour;
}

#sti-results-menu-option .badge.complete.active {
  margin-top: 2px;
  color: $btn-success-color;
  background-color: white;
}

#sti-results-menu-option .badge.complete.inactive {
  margin-top: 2px;
  background-color: $btn-success-color;
}

#sti-results-menu-option .badge.incomplete.active {
  margin-top: 2px;
  color: $btn-danger-color;
  background-color: white;
}

#sti-results-menu-option .badge.incomplete.inactive {
  margin-top: 2px;
  background-color: $btn-danger-color;
}

.badge.warning {
  background-color: $questionnare-red-colour;
}

/* Doctor Dashboard */

#doctor-message-form textarea {
  @extend .full-width;
}

#doctor-message-form input.contact-number {
  width: 150px;
}

#doctor-message-form div.message_body_sms {
  font-size: $default-font-size;
}

/* Thank You */
.thank-you {
  margin-top: 0;
  padding-top: 0;

  .thank-you-text {
    padding-top: 3 * $default-padding;

    p + h2 {
      margin-top: 40px;
    }
  }

  .smoker-info {
    font-size: 0.8em;
  }
}

@media (max-width: $screen-sm-min) {
  .thank-you .thank-you-text {
    padding-top: 0;
  }
}

.thank-you .thank-you-text ol {
  list-style-type: none;

  margin: 0;
  margin-left: 3em;
  padding: 0;

  counter-reset: li-counter;
}

.thank-you .thank-you-text ol > li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 0.5em;
  min-height: 3em;
  border-left: 2px solid #cccccc;
}

.thank-you .thank-you-text ol > li:before {
  position: absolute;
  top: 0;
  left: -1em;
  width: 0.8em;

  font-size: 2em;
  line-height: 1;
  font-weight: bold;
  text-align: right;
  color: #464646;

  content: counter(li-counter);
  counter-increment: li-counter;
}

// Treatments
#treatments-table {
  th {
    padding-left: 0px;
  }

  th.icon,
  td.icon {
    padding: 0px 8px;
  }

  td {
    vertical-align: middle;
    padding: 4px 0px 8px 0px;
  }
}

// Treatments and Sessions Filter: Inline Form
.treatments-filter-inline,
.sessions-filter-inline {
  margin-bottom: 2 * $default-margin;
}

.treatments-filter-inline .form-group,
.sessions-filter-inline .form-group {
  margin-right: 2 * $default-margin;
}

// STI Result
form#sti-result span.form-button label[for$='_test_result_non_negative']:hover,
form#sti-result span.form-button.selected label[for$='_test_result_non_negative'] {
  background-color: $btn-danger-color;
}

.sti-confirmation-non-negative {
  color: $btn-danger-color;
}

form#sti-result span.form-button label[for$='_test_result_negative']:hover,
form#sti-result span.form-button.selected label[for$='_test_result_negative'] {
  background-color: $btn-success-color;
}

.sti-confirmation-negative {
  color: $btn-success-color;
}

form#sti-result input#sti_result_dob {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  width: 150px;
}

form#sti-result label {
  // TODO This label should center vertically.
}

form#sti-result div.file-input-wrapper {
  padding-top: 10px;
}

// Campaigns Filter
.campaigns-filter-inline {
  margin-right: 0px;
  margin-bottom: 20px;
}

.campaigns-filter-inline .form-group {
  margin-right: 10px;
}

// Padding utility classes

.margin-bottom-small {
  margin-bottom: $small-margin;
}

.margin-top-small {
  margin-top: $small-margin;
}

.margin-top-tiny {
  margin-top: $tiny-margin;
}

.margin-bottom-tiny {
  margin-bottom: $tiny-margin;
}

.no-margin {
  margin: 0 !important;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.left-margin-0 {
  margin-left: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.height-0 {
  height: 0px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-top-30 {
  margin-top: 30px;
}

// text alignment

.text-left {
  text-align: left !important;
}

// responsive stylings
@media (max-width: $screen-md-min) {
  .text-center-medium {
    text-align: center;
  }
}

@media (max-width: $screen-sm-min) {
  .text-center-small {
    text-align: center !important;
  }

  .margin-top-40-small {
    margin-top: 40px;
  }
}

.dashboard-row .col-md-4,
.dashboard-row .col-md-6 {
  margin-bottom: 40px;
  padding: 0px 40px 40px 40px;
  text-align: center;
}

.dashboard-row .fa-large {
  font-size: 100px;
}

.dashboard-row a {
  margin-top: 20px;
  display: block;
}

.dashboard-notifications {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid $text-color;
}

.dashboard-notifications .badge {
  background-color: $background-color;
}

a.dashboard-notification:hover {
  text-decoration: none;
}

.dashboard-row a:hover {
  color: $link-hover-color;
}

// Landing Page
.landing-page {
  .form-question-section {
    margin: 0 0 20px 0;
  }

  h4 {
    margin: 20px 0 10px 0;
  }
}

@media (max-width: $screen-sm-min) {
  .landing-page.padding-top-bottom {
    padding: 10px 0;
  }

  .landing-page {
    .btn-qubico {
      margin: 10px 0;
    }

    h4 {
      margin: 14px 0 7px 0;
    }
  }
}

// Landing Page - Video Consultations
.landing-page.video-consultation {
  .icons {
    max-width: 440px;
    margin: 0 34px 32px 34px;
  }

  .reason-buttons {
    margin: 0 0 0 0;

    .btn-qubico {
      width: 220px;
    }
  }

  dt {
    margin-top: 8px;
  }

  #spinner p {
    line-height: 147px;
  }

  .thumbnail {
    border: 0;

    .caption {
      padding: 10px 0 0 0;

      p {
        text-align: center;
        font-size: 0.9em;
        font-style: italic;
        color: $text-color;
      }
    }
  }

  div.press {
    margin-top: 2 * $default-margin;

    .press-header {
      margin-bottom: 20px;
    }
  }

  .feature {
    margin-bottom: 16px;
  }
}

@media (max-width: $screen-sm-min) {
  .landing-page.video-consultation {
    #browsers {
      .browser-logo {
        margin-right: 8px;
      }
    }

    p {
      font-size: 15px;
    }

    .alert {
      margin-top: 20px;
    }

    .icons {
      margin: 16px 34px 16px 34px;
    }

    div.press {
      .press-header {
        margin-bottom: 16px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .landing-page.video-consultation {
    h4 {
      font-size: 22px;
    }

    #things-we-treat,
    #how-it-works h4 {
      text-align: left;
    }
  }
}

#browsers {
  margin: 0 auto 34px auto;

  .browser-logo {
    margin-right: 16px;
    max-width: 64px;
    max-height: 64px;
  }

  .appstore-logo,
  .browser-logo {
    margin-top: 34px;
  }
}

// Press

section.press {
  h3 {
    a {
      color: $text-color;
    }
  }

  h3.press-name {
    margin: 20px 0 0 0;
    text-align: center;
  }

  .press-date {
    text-align: center;
  }
}

// Press Section on Homepage

#press {
  padding-top: 40px;
  padding-bottom: 40px;

  .section-description {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  img {
    opacity: 0.5;
    filter: grayscale(1); /* Firefox 35+ */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  }

  /* Disable grayscale on hover */
  img:hover {
    opacity: 0.75;
    filter: none;
    -webkit-filter: grayscale(0);
  }

  .section-description {
    line-height: 0.8em;
  }
}

@media (max-width: $screen-xs-max) {
  section.press {
    h3.press-lead,
    h3.press-name {
      font-size: 16px;
    }
  }

  #press {
    padding-top: 20px;
    padding-bottom: 20px;

    .section-description {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
}

// Corporate Care Page
#corporate-care-page {
  .anchor-adjustment {
    padding-top: 59px;
    margin-top: -59px;
  }

  #header {
    h1 {
      color: #00c3b9;
      margin: 120px 0 5px 0;
      font-weight: 900;
      font-size: 47px;
      margin-top: 60px;
    }

    h2 {
      color: #69aee2;
      font-weight: 100;
      font-size: 43px;
      letter-spacing: 2pt;
      line-height: 43px;
    }

    #benefits-list {
      color: #00c3b9;

      p {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 30px;

        .side-highlight {
          font-weight: 900;
        }
      }
    }

    @media (max-width: $screen-lg-min) {
      h1 {
        margin-top: 75px;
        font-size: 43px;
        line-height: 43px;
      }

      h2 {
        font-size: 36px;
        line-height: 36px;
      }

      #benefits-list {
        p {
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width: $screen-sm-min) {
      h1 {
        text-align: center;
        margin-top: 0px;
      }

      h2 {
        text-align: center;
        margin-bottom: 0px;
      }
    }
  }

  section {
    margin: 0;
    padding-top: 60px 0;
  }

  .section-title {
    color: #fd278f;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.7pt;
  }

  .how-it-works-header {
    margin-bottom: 15px;
    text-align: center;
  }

  .how-it-works-text {
    font-size: 22px;
    margin-bottom: 40px;
    line-height: 24px;
  }

  section {
    padding: 60px 0;
  }

  #what-it-is {
    margin: 20px 0;
  }

  section.explanation,
  #what-it-is {
    background-color: #e2f2f8;
  }

  #our-doctors img {
    margin: 25px 0;
  }

  .corp-feature {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .keep-reading {
    margin: 40px 0;
  }

  #what-we-treat-heading {
    color: #6fb1dd;
    font-size: 22px;
    padding-top: 40px;
  }

  .corp-care-faq-btn {
    font-size: 20px;
    letter-spacing: 1px;
  }

  #what-it-is p,
  .explanation ul {
    margin-bottom: 25px;
  }

  .subtitle {
    color: $c-blue;
  }

  h4.subtitle {
    font-size: 20px;
  }

  @media (max-width: $screen-sm-min) {
    .section-title {
      text-align: center;
      font-size: 23px;
    }

    p,
    li {
      font-size: 16px;
    }

    .anchor-adjustment {
      padding-top: 50px;
      margin-top: -50px;
    }
  }

  .doctor-profile-img {
    img {
      width: 50%;
      border-radius: 161px;
      border: 2px solid #8bc6f6;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  section#contact-us {
    margin-bottom: 60px;
  }

  section#health-checks {
    background: image_url('corporate-care/health-check.png') no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 60px 0;
  }

  #contact-us p {
    margin-bottom: 25px;
  }
}

#corporate-care-faq {
  #header {
    text-transform: uppercase;

    h1 {
      color: #00c3b9;
      margin-bottom: 5px;
      font-weight: 900;
      font-size: 47px;
      text-align: center;
    }

    h2 {
      color: #69aee2;
      font-weight: 100;
      font-size: 43px;
      letter-spacing: 2pt;
      line-height: 43px;
      text-align: center;
    }
  }

  .section-title {
    color: #fd278f;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.7pt;
  }

  section {
    margin: 20px 0;
    padding: 20px 0;
  }

  section.explanation {
    background-color: #e2f2f8;
  }

  #faq-welcome {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 27px;
  }

  .question {
    margin-top: 20px;

    h3 {
      color: #6fb1dd;
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  .faq-seperator {
    color: $c-green;
  }

  .faq-seperator-blank {
    color: #ffffff;
  }

  @media (max-width: $screen-sm-min) {
    .question h3 {
      text-align: center;
    }

    .question p,
    li {
      margin-top: 10px;
      font-size: 16px;
    }

    .section-list {
      margin-bottom: 0px;
    }

    h1 {
      margin-top: 0px;
      font-size: 43px !important;
      line-height: 43px !important;
    }

    h2 {
      margin-bottom: 0px;
      font-size: 39px !important;
      line-height: 39px !important;
    }

    .correct-padding-mobile {
      padding-left: 20px;
    }
  }
}

// Payment Page

#payment-page {
  h3.title {
    margin-top: 10px;
  }

  .btn-qubico {
    margin: 5px 0;
  }

  #show-apply-coupon-form {
    color: $btn-primary-color;
    border-bottom: 1px dotted $btn-primary-color;
  }

  #show-apply-coupon-form:hover {
    text-decoration: none;
  }

  @media (max-width: $screen-sm-min) {
    h4 {
      margin-bottom: 10px;
    }

    p {
      font-size: 15px;
      margin-bottom: 5px;
    }

    .form-question-section {
      border-radius: 0px;
    }

    #show-apply-coupon-form {
      font-size: 16px;
    }
  }
}

// Stripe Element Form

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  margin-bottom: 12px;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #a94442;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

// Datepicker

#ui-datepicker-div {
  .ui-datepicker-month {
    font-weight: normal;
  }
}

// Questionnaire Export Styling

.questionnaire-export .panel {
  border: none;
}

.questionnaire-export .panel-default > .panel-heading,
.questionnaire-export .panel-heading {
  background-color: #ffffff;
  font-weight: bold;
  border: none;
  color: $text-color;
}

.questionnaire-export #questionnare-form label {
  opacity: 1;
}

.wd-error-page {
  margin-top: 80px;
  margin-bottom: 80px;
}

label abbr {
  display: none;
}

// Home Test Registration

#home-test-registration-form {
  text-align: center;
}

@media (min-width: $screen-sm-min) {
  #home-test-registration-form {
    text-align: initial;
  }
}

.home-banner {
  background-color: $c-blue;
  padding: 15px 20px 12px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.1em;
  position: relative;
  z-index: 1;

  &.grey {
    background-color: #9c9c9c;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.navbar-fixed-top {
  top: 25px;
}

.bar-fixed {
  top: 0px;
}

.navbar-inverse {
  z-index: 1030;
  border: 1px solid transparent;
}

.affix {
  top: 0;
  width: 100%;
}

.affix + .container-fluid {
  padding-top: 50px;
}

.label-red {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  padding: 4px 5px 3px;
  background: #ff5959;
  border-radius: 4px;
  font-weight: 500;
  display: inline-block;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  filter: alpha(opacity=20);
  opacity: 0.2;
  text-decoration: none;
}

.patient-selection {
  padding-bottom: 30px;

  @include sm-breakpoint {
    padding-bottom: 0;
  }

  a,
  button {
    margin: 0;

    + a,
    + button {
      margin-top: 10px;
    }
  }

  .patient-selection-text {
    margin-bottom: 30px;
  }

  .patient-selection-form {
    margin-bottom: 10px;
  }
}

.treatment-form .form-group {
  padding: 50px 0;

  + .question-label-text {
    padding-top: 0;
  }

  @include xs-breakpoint {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.form-inline .input-group > .field_with_errors > .form-control {
  width: 100%;
}

.app-store-icons {
  margin-bottom: 0;
  margin-top: 6px;
}

.container.login-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.container .quotes-container {
  max-width: 500px;
  padding: 20px;
  font-size: 20px;
  font-family: soleil, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.container .logo-container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90%;
  }
}

.container.devise-container {
  max-width: 500px;
  padding: 20px;
  font-size: 20px;
  font-family: soleil, sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    font-weight: bold;
  }

  .lead-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lead-header {
    font-size: 32px;
    margin-bottom: 18px;
  }

  .lead-description {
    line-height: 1.2;
    max-width: 80%;
  }

  .helper-links-container {
    margin-top: 10px;

    a {
      color: #a8afbc;
      text-decoration: underline;
    }

    font-size: 14px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

#reset-password.container {
  .form-container {
    margin-top: 25px;
  }
}

.text-hh-blue {
  color: rgba(26, 68, 108, 1);
}

.text-hh-light-blue {
  color: #2fbcb6;
}

.devise-footer {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #9ec1c6;
  a {
    color: #9ec1c6;
  }
}

.btn-round {
  border-radius: 60rem;
}

.bg-hh-orange {
  background-color: #ebb63e;
}

.vertical-divider {
  display: flex;
  min-height: 100%;
  border-left: 2px solid rgba(26, 68, 108, 1);
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
}

.devise-container-wrapper {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mh-80vh {
  min-height: 80vh;
}
