// List
.hh-inbox-msg-when {
  position: relative;
  left: 0;
  font-size: 0.86rem;
  transition: $transition;
}

.hh-inbox-list-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-left: 3px solid transparent;
  border-right: 1px solid color-gray('200');
  border-bottom: 1px solid color-gray('200');
  background-color: darken(color('white'), 0.7%);
  transition: $transition;

  &:hover {
    .subject {
      color: theme-color('secondary');
    }

    .hh-inbox-msg-when {
      left: 0.2rem;
    }
  }

  &:hover,
  .open & {
    position: relative;
    padding-left: 1.2rem;
    border-bottom-color: color-gray('400');
    border-left-color: theme-color('secondary');
    box-shadow: 1px 1px 1px 1px color('shadow');
  }

  .open & {
    background-color: transparentize(theme-color('secondary'), 0.93);
  }

  &.unread {
    font-weight: 700;
    background-color: darken(color('white'), 0.7%);
  }

  .title {
    .from {
      font-size: 0.9rem;
      font-weight: 500;
    }

    .dot-icon {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      margin-left: 0.2rem;
      background-color: theme-color('secondary');
      border-radius: 100px;
    }
  }

  .subject {
    color: theme-color('secondary');
  }
}

// Message
.hh-inbox-msg-body {
  border-radius: 4px;

  p {
    @extend .hh-text;
  }
}

// Thread
.hh-inbox-thread-item {
  .hh-inbox-msg {
    @include hide;

    a {
      color: $color-secondary;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.open {
    .hh-inbox-msg {
      @include show;
    }
  }
}

.hh-inbox-thread-link {
  padding: 0.8rem 0;
  flex-basis: 100%;
  font-size: 0.9rem;
  border-top: 1px solid color-gray('200');
  user-select: none;

  .open & {
    padding-bottom: 0;
  }

  .hh-initials {
    margin-right: 0.8rem;
  }
}

.hh-inbox-thread-title {
  flex-basis: 100%;
}

.hh-inbox-thread-header {
  a {
    margin-right: 0.8rem;
    width: $initials-size;
  }

  > div {
    width: 100%;
  }
}

.hh-inbox-thread-subject {
  width: 100%;
}

.hh-inbox-thread-headline {
  width: 1vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hh-inbox-message-subject {
  color: color-gray('600');
}

.hh-inbox-reply-wrapper {
  @extend .hh-form-control;
  padding: 0;
  transition: 0.35s;

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(78, 78, 78, 0.3), 0 2px 6px 2px rgba(70, 70, 70, 0.15);
  }

  &.invalid {
    @extend .hh-form-control-invalid;
  }

  .hh-form-textarea {
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .hh-custom-file-btn {
    border-radius: 0;
  }
}

.fake-attached-file-wrapper {
  width: 100%;
}

.fake-attached-file {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: color-gray('200');

  @include breakpoint(min-width, bp-size('sm')) {
    width: 70%;
  }

  + .fake-attached-file {
    margin-top: 10px;
  }

  > a {
    @include ellipsis(94%);
    font-size: 0.9rem;
    color: color('blue');
  }

  > button {
    color: color-gray('900');

    &:hover {
      color: color('blue');
    }
  }
}

.fake-file-remove-all {
  a {
    color: color('default');

    &:hover {
      color: theme-color('danger');
    }
  }
}

.hh-inbox-remove-all-attachments {
  font-size: 0.95rem;
}

.hh-inbox-attachment {
  @extend .hh-form-control;
  padding: 0;
  background-color: color-gray('100');
  border-top-style: dashed;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
  border-bottom-right-radius: $form-control-border-radius;
  border-bottom-left-radius: $form-control-border-radius;

  .hh-form-error {
    font-size: 1rem;
  }
}
