@mixin breakpoint($feature, $value, $feature2: null, $value2: null) {
  // Set global device param
  $media: only screen;

  @if $feature2 {
    @media #{$media} and ($feature: $value) and ($feature2: $value2) {
      @content;
    }
  } @else {
    @media #{$media} and ($feature: $value) {
      @content;
    }
  }
}

@function bp-size($size) {
  @if not map-has-key($grid-breakpoints, $size) {
    @warn 'No breakpoint found for `#{$size}` in $breakpoint map. Property omitted.';
  }

  @return map-get($grid-breakpoints, $size);
}
