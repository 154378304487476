// Common: Helper Classes and Placeholders
// --------------------------------------------

%ui-clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after { clear: both; }
}

// Image replacement
%ui-ir {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

// Alignments
%ui-align-left { text-align: left; }
%ui-align-center { text-align: center; }
%ui-align-right { text-align: right; }

.ui-align-left { @extend %ui-align-left; }
.ui-align-center { @extend %ui-align-center; }
.ui-align-right { @extend %ui-align-right; }

%ui-auto-center {
  margin-left: auto;
  margin-right: auto;
}

// Floats
%ui-pull-left { float: left; }
%ui-pull-right { float: right; }
.ui-pull-left { @extend %ui-pull-left; }
.ui-pull-right { @extend %ui-pull-right; }

// Center
%ui-center-vertically {
  display: table-cell;
  vertical-align: middle;
}

// Display
%ui-inline-top {
  display: inline-block;
  vertical-align: top;
}

%ui-inline-middle {
  display: inline-block;
  vertical-align: middle;
}
.ui-inline-middle { @extend %ui-inline-middle; }

%ui-inline-bottom {
  display: inline-block;
  vertical-align: bottom;
}

%table-align { display: table; }

%ui-show { display: block; }
%ui-hide { display: none; }

// Visibillity
%ui-invisible {
  opacity: 0;
  visibility: hidden;
}

%ui-visible {
  opacity: 1;
  visibility: visible;
}

// Box space
%ui-no-padding { padding: 0 !important; }
%ui-no-margin { margin: 0 !important; }
%ui-no-padding-bottom { padding-bottom: 0 !important; }

// Text
%ui-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%ui-text-uppercase { text-transform: uppercase; }

// Responsive
.ui-show { display: block !important; }
.ui-hide { display: none !important; }

.ui-show-md {
  display: none !important;

  @include breakpoint(max-width, $screen-sm-min) {
    display: block !important;
  }
}

.ui-hide-md {
  @include breakpoint(max-width, $screen-sm-min) {
    display: none !important;
  }
}

.ui-show-lg {
  display: none !important;

  @include breakpoint(max-width, $screen-md-min) {
    display: block !important;
  }
}

.ui-hide-lg {
  @include breakpoint(max-width, $screen-md-min) {
    display: none !important;
  }
}

.ui-hide-webview {
  @include is-webview {
    display: none !important;
  }
}

.ui-show-webview {
  display: none !important;

  @include is-webview {
    display: block !important;
  }
}

.retina-wrapper {
  .retina { display: none; }

  @include retina {
    .non-retina { display: none; }
    .retina { display: block; }
  }
}

// Text
.text-underline { text-decoration: underline; }

.text-small {
  font-size: $default-font-size - 2px;
  line-height: $default-line-height - 2px;
}

// Flexbox
.d-flex { display: flex; }
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }

.order-first { order: -1; }
.order-last { order: ($order-length + 1); }

@for $i from 1 through $order-length {
  .order-#{$i} { order: $i; }
}

@include breakpoint(min-width, $screen-sm-min) {
  .d-sm-flex { display: flex; }
  .flex-sm-row { flex-direction: row; }
  .flex-sm-column { flex-direction: column; }

  .order-sm-first { order: -1; }
  .order-sm-last { order: ($order-length + 1); }

  @for $i from 1 through $order-length {
    .order-sm-#{$i} { order: $i; }
  }
}

// Off screen rendering
.off-screen {
  position: absolute;
  left: -1000px;
  top: -1000px;
}

// Background colors

.icon-success {
  color: $c-green;
}

.icon-warning {
  color: $btn-warning-color;
}

.icon-white {
  color: $light-color;
}