// App classname that will prepend all classes
$app-namespace: 'hh-';

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 2
    ),
    6: (
      $spacer * 2.5
    ),
    7: (
      $spacer * 3
    ),
    8: (
      $spacer * 3.5
    ),
    9: (
      $spacer * 4
    ),
    10: (
      $spacer * 4.5
    )
  ),
  $spacers
);

// Colors
$color-white: #ffffff;
$color-gray-100: #f8f9fa;
$color-gray-200: #e9ecef;
$color-gray-300: #dee2e6;
$color-gray-400: #ced4da;
$color-gray-500: #adb5bd;
$color-gray-600: #6c757d;
$color-gray-700: #495057;
$color-gray-800: #343a40;
$color-gray-900: #212529;
$color-black: #000000;
$color-blue: #1155cc;
$color-default: $color-gray-800;

$colors: (
  'black': (
    'base': $color-black
  ),
  'white': (
    'base': $color-white
  ),
  'blue': (
    'base': $color-blue
  ),
  'bg': (
    'base': #fefefe
  ),
  'shadow': (
    'base': #d3d3d3
  ),
  'default': (
    'base': $color-default
  )
);

$color-grays: (
  '100': $color-gray-100,
  '200': $color-gray-200,
  '300': $color-gray-300,
  '400': $color-gray-400,
  '500': $color-gray-500,
  '600': $color-gray-600,
  '700': $color-gray-700,
  '800': $color-gray-800,
  '900': $color-gray-900,
  'base': $color-gray-400
);

$color-primary: #1a446c;
$color-secondary: #70adb5;

$theme-colors: (
  'primary': (
    'light': #2a5d8d,
    'base': $color-primary
  ),
  'secondary': (
    'light': #80c0c9,
    'base': $color-secondary
  ),
  'tertiary': (
    'light': #f7c744,
    'base': #f5b400
  ),
  'quaternary': (
    'light': #43b99e,
    'base': #439a86
  ),
  'quinary': (
    'light': #a5427e,
    'base': #893168
  ),
  'senary': (
    'light': #b8a0c9,
    'base': #9f87af
  ),
  'success': (
    'light': #57cc70,
    'base': #46b45e
  ),
  'danger': (
    'light': #e05757,
    'base': #c23939
  ),
  'warning': (
    'light': #eca84e,
    'base': #dc9232
  ),
  'info': (
    'light': #55c4d8,
    'base': #3ab6cc
  ),
  'light': (
    'light': $color-gray-200,
    'base': lighten($color-gray-200, 3%),
    'text': $color-default
  ),
  'dark': (
    'light': $color-gray-700,
    'base': $color-gray-900
  )
);

$theme-colors-set: (
  'primary',
  'secondary',
  'tertiary',
  'quaternary',
  'quinary',
  'senary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark'
);

// Overwritten colors
$c-white: $color-white;
$c-primary: $color-primary;

// Text
$font-size-root: 16px;
$font-size: 1rem;
$font-line-height: 1.7;
$font-weight: 500;
$font-family-system: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Lucida',
  'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-open-sans: 'Open Sans', $font-family-system;
$font-family-lato: 'Lato', $font-family-open-sans;
$font-family-montserrat: 'Montserrat', $font-family-open-sans;

// Others
$transition: 0.06s ease-in-out;
$box-shadow: 3px 3px 0;
$initials-size: 50px;
$border-radius: 4px;
$transition: 0.06s;

// Form
$form-control-border-radius: $border-radius;

$z-indexes-map: map-merge(
  $z-indexes-map,
  (
    'layout': (
      'main-nav': 1000,
      'sidebar-overlay': 1090,
      'sidebar': 2000
    )
  )
);
