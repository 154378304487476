@import './patients/variables';

#date-select-calendar {
  table {
    table-layout: fixed;
    width: 100%;

    td {
      width: 25%;
      border-top: none;

      .btn-calendar {
        width: 100%;
      }
    }

    th {
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    margin-top: 30px;
    margin-bottom: 30px;

    .btn-calendar {
      padding: 5px 0 !important;
    }

    table {
      border-collapse: separate;
      border-spacing: 0 15px;

      td {
        padding: 4px;
      }
    }
  }
}

.service-details {
  .vl {
    border-left: 1px solid;
    padding: 0 2px;
  }
}

.card {
  text-align: left;
  margin-bottom: 10px;
  border: solid 1px #bbb;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  .card-body {
    padding: 15px;
    flex-grow: 1;
    .card-title {
      font-weight: 700;
      color: #2baca6;
      margin-bottom: 5px;
      @media (min-width: 651px) {
        width: auto;
        display: inline-block;
      }
      .service-icon {
        margin-right: 10px;
        color: #2baca6;
      }
    }
    .card-title.clinic-name-text-color {
      @media (min-width: 850px) {
        width: auto;
        width: 200px;
      }
      @media (min-width: 990px) and (max-width: 1090px) {
        width: 160px;
      }
    }
    .card-subtitle {
      font-style: italic;
      margin-bottom: 5px;
    }
    .clinic-address {
      display: inline-block;
      width: 100%;
      @media (min-width: 651px) {
        max-width: 60%;
      }
    }
    .clinic-address-text-color:not(:empty) {
      font-weight: normal;
      font-size: 14px;
      color: #9A9999;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      border-left: 0;
      margin: 15px auto 0 auto;
      padding: 0 0 0 12px;
      line-height: 18px;
      text-align: center;
      top: 26px;
      @media (min-width: 651px) {
        border-left: 1px solid $c-grey;
        margin: 0 0 0 12px;
        text-align: left;
      }
    }
  }

  .clinic-card-body {
    @media (min-width: 651px) {
      display: flex;
    }
  }

  .card-link {
    margin:  0 10px 10px;
    align-self: flex-end;
    @media (min-width: 651px) {
      margin: 10px;
    }

    .btn {
      font-size: 1rem;
      font-weight: 700;
      font-family: $font-family-montserrat;
      text-align: center;
    }
  }

  .clinic-card-link {
    @media (min-width: 651px) {
      align-self: flex-start !important;
    }
  }
}

@media (max-width: 650px) {
  .card {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

#calendar-load-more {
  margin: 15px 0;

  a {
    text-transform: uppercase;
    font-size: 16px;
  }
}

#clinician-selection {
  margin: 20px auto;
  max-width: 450px;

  .clinician {
    margin-bottom: 10px;
    border: 2px solid #b7b7b7;
    background-color: $c-white;
    color: #262326;
    font-weight: 300;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    max-width: 300px;

    &:hover {
      border-color: $c-green;
      background-color: $c-green;
      color: #FFF;
    }
  }

  label {
    padding: 15px 0px;
    width: 90%;
    margin: 0;
    font-size: 16px;
    cursor: pointer;
    font-weight: normal;
  }
}

.patient-details {
  .field_with_errors {
    display: inline;
  }

  label {
    &.required {
      &:after {
        margin-left: 2px;
        content: '*';
      }
    }
  }
}

#questionnaire {
  .question {
    margin-bottom: 1.2em;

    .text {
      margin-bottom: 0.8rem;
    }
  }
}

#booking-confirmation {
  border: 1px solid #BBB;
  border-radius: 5px;
  width: 60%;
  margin: 30px auto;
  padding: 15px;
  font-size: 16px;
  line-height: 30px !important;

  @media (max-width: 650px) {
    width: 90%;
  }
}

.time-selection {
  float: none;
  margin: 20px auto;
  width: auto;
  max-width: 450px;
  display: table;

  .btn-hours {
    margin-bottom: 7px;
  }

  @media (max-width: 375px) {
    width: 100% !important;

    .btn-hours {
      width: 75px;
    }
  }

  @media (min-width: 376px) and (max-width: 450px) {
    width: 100% !important;

    .btn-hours {
      width: 85px;
    }
  }

  .clinician-selection {
    margin: 20px auto;
    width: auto;
    max-width: 450px;
  }
}

.booking-confirmation {
  .fa-check-circle {
    font-size: 70px;
  }
}

.unavailable-service-warning {
  .fa-frown {
    color: $c-green;
    font-size: 70px;
  }
}

.unsuitable-warning {
  .fa-times-circle {
    color: $c-red;
    font-size: 70px;
  }
}

.patient-booking-step-description {
  margin: 10px 0;
}

.patient-booking-content {
  padding: 15px 0;
}

.normal-font-weight {
  font-weight: normal;
}

.online-booking-disabled {
  padding: 100px 0;

  a {
    @extend .hh-link;
  }
}

.search-form-wrapper {
  position: relative;
  margin: 0 auto 20px;

  @include breakpoint(min-width, $break-mobile) {
    max-width: 400px;
  }
}

.search-reference {
  text-align: left;
  color: #9A9999;
  font-size: 14px;

  @media (max-width: 410px) {
    font-size: 12px;
  }

  @media (max-width: 367px) {
    font-size: 9px;
  }
}

.search-form-input {
  height: 40px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
}

.search-form-icon {
  position: absolute;
  top: 20px;
  right: 10px;
  transform: translateY(-50%);
}

// Card
.booking-card {
  .card-body {
    height: auto;
    position: relative;
    @include breakpoint(min-width, $card-breakpoint) {
      flex-basis: 60%;
    }
  }

  .card-link {
    @include breakpoint(min-width, $card-breakpoint) {
      text-align: right;
      flex-basis: 180px;
    }
  }
}

#clinic-selection .booking-card {
  align-items: start;
}

// Generic online booking
.online-booking {
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &.justify-content-center {
      justify-content: center;
    }
    &.flex-direction-column-reverse {
      flex-direction: column-reverse;
      margin: 10px 0;
      row-gap: 10px;
      align-items: center;

      .btn-back-patient-booking {
        border: 1px solid $c-fade;
      }
    }
  }
}

.success-icon-wrapper {
  font-size: 75px;
  color: #2baca6;
}

.iframe-min-height {
  min-height: 500px;
}

.selection-button {
  display: none;
}
